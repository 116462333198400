import type { SVGProps } from "react";
export const Wirings = (props: SVGProps<SVGSVGElement>) => <svg width="68" height="75" viewBox="0 0 68 75" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="Wirings" data-sentry-source-file="wirings.tsx">
    <path d="M53.2347 60.7449C53.2347 60.7449 52.6809 43.2181 67.9842 30.211C45.3313 17.5555 23.3831 5.15112 23.3831 5.15112C23.3831 5.15112 3.14652 12.4833 8.83492 34.3793C15.6308 38.6982 53.2347 60.7449 53.2347 60.7449Z" fill="#494851" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M6.41846 23.4312L57.0603 51.9562" stroke="#FF7171" stroke-width="8" stroke-miterlimit="10" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M39.3911 31.0144L59.7284 42.5148" stroke="#9EAEB7" stroke-width="8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M9.13672 13.9397L28.5175 24.8877" stroke="#9EAEB7" stroke-width="8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M29.1215 53.7142C28.8698 54.0658 28.5677 54.4675 28.2657 54.7689C27.712 54.5177 27.1582 54.2667 26.6045 54.0658C27.1079 53.8147 27.5609 53.4631 28.014 53.1116C28.4167 53.3125 28.7691 53.5133 29.1215 53.7142Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M26.4033 52.6092C26.0509 53.011 25.6482 53.3625 25.2958 53.7643C24.7924 53.463 24.289 53.1617 23.7856 52.8603C24.5407 52.3581 25.4972 52.4083 26.4033 52.6092Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M25.4466 53.7141C24.1881 54.0154 22.7786 53.5132 22.5772 52.107C22.3255 50.7008 23.3827 49.8972 24.7922 49.8972C26.1514 49.8972 27.5609 50.6506 28.7187 51.3537C27.8629 52.4083 26.8561 53.3625 25.4466 53.7141Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M25.7993 49.2948C25.4469 49.6965 25.0442 50.0481 24.6918 50.4498C24.1884 50.1485 23.685 49.8472 23.1816 49.5459C23.9367 49.0437 24.8932 49.1441 25.7993 49.2948Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M24.8428 50.3994C23.5843 50.7007 22.1748 50.1483 21.9734 48.7924C21.772 47.3862 22.8292 46.4823 24.1884 46.5325C25.4972 46.5827 26.9067 47.336 28.0645 47.9889C27.2591 49.0937 26.2523 50.0479 24.8428 50.3994Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M28.165 48.0391C28.7691 48.3906 29.2221 48.8928 29.3228 49.5959C29.4235 50.2989 29.1718 50.8513 28.7187 51.3535" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M27.7627 44.373C28.3668 44.825 28.7192 45.4779 28.8199 46.2312C28.8702 46.9343 28.6688 47.537 28.1654 48.0894" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M28.5175 50.4498C28.2658 50.8014 27.9637 51.2031 27.6617 51.5044C27.108 51.2533 26.5542 51.0023 26.0005 50.8014C26.5039 50.5503 26.957 50.1987 27.41 49.8472C27.8127 49.9978 28.1651 50.1987 28.5175 50.4498Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M29.7759 48.7422C29.5745 48.4409 29.3732 48.1898 29.1215 47.9387C28.8194 48.2903 28.5173 48.6418 28.2656 48.9934C28.467 49.144 28.5677 49.3951 28.618 49.6462C29.0208 49.3449 29.4235 49.0436 29.7759 48.7422Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M27.9638 47.1353C27.7121 47.4869 27.41 47.8886 27.108 48.19C26.5543 47.9389 26.0005 47.6878 25.4468 47.4869C25.9502 47.2358 26.4536 46.8843 26.8563 46.5327C27.2087 46.7336 27.5611 46.8842 27.9638 47.1353Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M25.2455 45.8796C24.8428 46.2814 24.4904 46.6832 24.0877 47.085C23.6346 46.7836 23.1816 46.4321 22.7285 46.0805C23.534 45.6286 24.3897 45.6788 25.2455 45.8796Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M24.2889 47.085C22.9297 47.3863 21.8222 46.6331 21.6712 45.2771C21.5705 43.871 22.6276 42.7159 24.0372 42.7159C25.3963 42.6656 26.7052 43.5696 27.7119 44.3731C26.8562 45.5282 25.799 46.7335 24.2889 47.085Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M25.0947 41.9124C24.6919 42.3643 24.2892 42.8665 23.8865 43.3185C23.4335 42.967 22.9804 42.6656 22.5273 42.3141C23.3328 41.7617 24.1885 41.7115 25.0947 41.9124Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M24.0877 43.268C22.7285 43.6698 21.621 42.9165 21.47 41.5606C21.319 40.1544 22.2251 38.9492 23.6849 38.7483C25.0945 38.5977 26.3529 39.4012 27.4604 40.2047C26.655 41.5104 25.5978 42.8161 24.0877 43.268Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M27.4604 40.2549C28.1149 40.7069 28.4672 41.4099 28.5176 42.2135C28.5679 43.017 28.2659 43.7703 27.7625 44.3729" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M26.8062 36.0867C27.4606 36.4884 27.9136 37.1413 28.0646 37.9448C28.2156 38.7484 27.9639 39.6021 27.4606 40.255" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M27.662 43.218C27.36 43.6699 27.0579 44.1219 26.7055 44.5237C26.2021 44.2224 25.6988 43.921 25.145 43.7202C25.6988 43.3686 26.1518 42.9669 26.6049 42.5149C26.9572 42.766 27.3096 42.9669 27.662 43.218Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M29.0209 41.0585C28.8699 40.6568 28.6686 40.3554 28.4169 40.0039C28.1148 40.5061 27.8128 40.9581 27.5107 41.4101C27.7121 41.6109 27.7625 41.9122 27.8128 42.2136C28.2155 41.862 28.6685 41.4603 29.0209 41.0585Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M27.2591 39.0999C27.0074 39.6021 26.7054 40.0541 26.4034 40.506C25.8999 40.2047 25.3965 39.9034 24.8428 39.7527C25.3462 39.351 25.7993 38.9492 26.202 38.447C26.6047 38.5977 26.9571 38.7985 27.2591 39.0999Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M24.6415 37.9448C24.2892 38.447 23.9368 38.9493 23.5844 39.4515C23.1313 39.1502 22.6279 38.8488 22.1245 38.5977C22.8293 37.9448 23.7354 37.8444 24.6415 37.9448Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M23.7354 39.3509C22.4266 39.8531 21.2185 39.2505 20.9668 37.8946C20.7151 36.5386 21.4198 35.2831 22.8293 34.9818C24.1885 34.6804 25.598 35.3835 26.8062 36.0364C26.1518 37.3923 25.1953 38.7985 23.7354 39.3509Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M23.6854 34.0779C23.3834 34.5801 23.0814 35.0823 22.7793 35.5844C22.2759 35.3334 21.7222 35.1325 21.2188 34.8814C21.9235 34.2788 22.7793 34.1281 23.6854 34.0779Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M22.9804 35.5341C21.7722 36.1367 20.4634 35.735 19.96 34.379C19.4566 33.0231 20.0607 31.9183 21.4702 31.4663C22.8294 31.0646 24.3396 31.5165 25.5981 32.0689C25.0947 33.4751 24.3396 34.8812 22.9804 35.5341Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M25.6987 32.1194C26.4035 32.4207 26.9572 32.9731 27.1585 33.7766C27.3599 34.5801 27.2089 35.3837 26.8565 36.0867" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M24.1382 28.5034C24.8933 28.7043 25.4974 29.1563 25.7994 29.9096C26.1015 30.6629 26.0511 31.4162 25.6987 32.1193" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M26.5041 34.9316C26.3028 35.4338 26.1014 35.936 25.7994 36.388C25.2456 36.1369 24.7423 35.8858 24.1382 35.7853C24.5912 35.3836 24.9939 34.9316 25.3463 34.3792C25.749 34.5298 26.1517 34.7307 26.5041 34.9316Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M27.4105 32.5713C27.1588 32.2197 26.9071 31.9686 26.6051 31.7175C26.4037 32.2197 26.2023 32.7219 26.001 33.1739C26.2527 33.3246 26.4037 33.6259 26.5044 33.877C26.8064 33.4752 27.1085 33.0232 27.4105 32.5713Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M25.2458 31.1149C25.0948 31.6171 24.8934 32.0691 24.6921 32.5713C24.1383 32.3704 23.5343 32.1695 22.9302 32.1193C23.3329 31.6673 23.6853 31.2153 24.0377 30.7131C24.4404 30.7634 24.8431 30.914 25.2458 31.1149Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M22.326 30.6125C22.0743 31.1148 21.8226 31.617 21.5709 32.1192C21.0171 31.9685 20.4634 31.7676 19.9097 31.617C20.4634 30.9139 21.3695 30.713 22.326 30.6125Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M21.722 32.0189C20.5138 32.722 19.1546 32.4709 18.5506 31.1651C17.9465 29.8594 18.5506 28.7043 19.9097 28.2021C21.1682 27.6999 22.8295 28.1017 24.1383 28.5035C23.6349 29.9096 23.0308 31.2656 21.722 32.0189Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M20.6144 27.2981C20.4131 27.8003 20.1614 28.3025 19.96 28.8047C19.4062 28.7043 18.8525 28.6038 18.3491 28.4532C18.8525 27.7501 19.7587 27.499 20.6144 27.2981Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M20.111 28.7042C19.0538 29.4073 17.6443 29.4073 17.0402 28.202C16.4361 26.9967 17.0402 25.9421 18.2987 25.3394C19.5069 24.787 21.0674 24.8372 22.3259 25.1888C21.9735 26.4945 21.2688 27.9007 20.111 28.7042Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M22.376 25.189C23.0807 25.3396 23.6848 25.6911 24.0876 26.3942C24.4399 27.0973 24.3896 27.8506 24.1379 28.5537" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M20.4634 22.0752C21.1681 22.1254 21.8226 22.4769 22.2253 23.1298C22.628 23.7827 22.628 24.4858 22.3763 25.1889" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M23.5847 27.5491C23.4336 28.0513 23.333 28.5535 23.1316 29.0055C22.5275 28.9051 21.9234 28.7544 21.3193 28.7544C21.7221 28.3024 22.0241 27.8002 22.3261 27.2478C22.7792 27.3482 23.1819 27.4487 23.5847 27.5491Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M24.1887 25.2894C23.8867 25.0383 23.635 24.8374 23.2826 24.6868C23.1316 25.189 22.9806 25.6409 22.8799 26.1431C23.1316 26.2435 23.3329 26.4444 23.4839 26.6453C23.6853 26.1933 23.937 25.7413 24.1887 25.2894Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M21.8223 24.3351C21.7216 24.7871 21.5706 25.2893 21.3693 25.7413C20.8155 25.6408 20.2114 25.6911 19.6577 25.7413C20.0101 25.2893 20.3625 24.7871 20.6142 24.2346C21.0672 24.1844 21.4699 24.1844 21.8223 24.3351Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M19.0031 24.3352C18.8017 24.8876 18.6004 25.3899 18.3487 25.8921C17.7949 25.8419 17.2915 25.6912 16.7378 25.641C17.2915 24.8876 18.1473 24.5863 19.0031 24.3352Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M18.55 25.7915C17.5936 26.5448 16.2344 26.595 15.5297 25.5404C14.8249 24.4858 15.278 23.3809 16.4358 22.6779C17.4929 21.9748 19.1541 21.9748 20.463 22.1254C20.3623 23.4814 19.6072 24.988 18.55 25.7915Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M16.9897 21.6233C16.8386 22.1255 16.6876 22.6277 16.5869 23.1299C16.0332 23.1299 15.5298 23.1802 14.9761 23.1802C15.3788 22.4268 16.1842 21.9748 16.9897 21.6233Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M16.738 23.0295C15.8822 23.8833 14.5734 24.1846 13.7176 23.2806C12.8618 22.3767 13.1135 21.2216 14.1706 20.4181C15.1774 19.6648 16.8387 19.3132 18.1475 19.3132C18.1475 20.7194 17.6441 22.0753 16.738 23.0295Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M18.1475 19.3131C18.8522 19.2629 19.6073 19.514 20.0603 20.1167C20.5134 20.6691 20.614 21.3722 20.5134 22.0753" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M15.2783 17.003C15.9831 16.8523 16.8389 17.003 17.3926 17.5052C17.9464 17.9572 18.1981 18.61 18.0974 19.3131" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M19.8084 21.3218C19.8084 21.7738 19.7581 22.2258 19.6071 22.6778C19.003 22.6778 18.399 22.6778 17.8452 22.7782C18.1473 22.3262 18.4493 21.824 18.6003 21.3218C19.003 21.2716 19.4057 21.2716 19.8084 21.3218Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M19.9595 19.1122C19.6071 18.9113 19.2547 18.8109 18.9023 18.7104C18.9023 19.1624 18.9023 19.6646 18.9023 20.1166C19.154 20.1668 19.3554 20.3175 19.5568 20.5184C19.6574 20.0664 19.8085 19.5642 19.9595 19.1122Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M17.3424 18.7104C17.3424 19.1624 17.3927 19.6144 17.3424 20.0664C16.7887 20.1668 16.1846 20.2673 15.6309 20.4179C15.8826 19.9157 16.0336 19.4135 16.0839 18.8611C16.4866 18.7607 16.9397 18.7104 17.3424 18.7104Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M14.473 19.4639C14.473 19.9661 14.473 20.4683 14.3723 20.9203C13.8185 21.0207 13.2647 21.1211 12.6606 21.2718C13.0634 20.3678 13.6675 19.8656 14.473 19.4639Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M14.5234 20.7694C13.7683 21.6733 12.4595 22.2257 11.4023 21.5227C10.3452 20.8196 10.3955 19.7148 11.352 18.7104C12.2581 17.8065 13.9697 17.1536 15.2785 17.0029C15.5302 18.2584 15.3288 19.765 14.5234 20.7694Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M11.5029 17.7061C11.6036 18.2082 11.6539 18.6602 11.7043 19.1624C11.1002 19.3131 10.5464 19.5641 9.99268 19.765C10.0934 18.8611 10.7981 18.2082 11.5029 17.7061Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M11.7544 19.0119C11.0999 20.0665 9.7911 20.7696 8.58294 20.2674C7.37478 19.7652 7.22374 18.5599 8.12987 17.4551C8.98565 16.4004 10.4958 15.5467 11.9054 15.2454C12.3081 16.5009 12.5095 17.8066 11.7544 19.0119Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M11.9053 15.2453C12.6604 15.0444 13.4658 14.9942 14.1706 15.3457C14.8753 15.6973 15.1774 16.2999 15.278 17.003" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M8.43213 13.6884C9.13688 13.3871 9.94229 13.3369 10.647 13.6382C11.3518 13.9395 11.7545 14.5422 11.9055 15.2452" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M14.3217 16.5007C14.4224 16.9527 14.5734 17.3545 14.5734 17.8065C13.9693 18.0073 13.3653 18.158 12.8115 18.4091C12.9625 17.9069 13.0632 17.3545 13.0129 16.8021C13.4659 16.7016 13.919 16.6012 14.3217 16.5007Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M13.7179 14.4418C13.3152 14.3414 12.9125 14.3414 12.5098 14.3916C12.6608 14.8436 12.7615 15.2956 12.9125 15.7475C13.2145 15.6973 13.4662 15.7978 13.7682 15.8982C13.7179 15.396 13.7179 14.8938 13.7179 14.4418Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M10.8986 14.7932C11.0497 15.195 11.2007 15.647 11.3014 16.0487C10.6973 16.2998 10.1435 16.5509 9.64014 16.9025C9.74082 16.3501 9.74084 15.8479 9.6905 15.2954C10.0932 15.0945 10.4959 14.8937 10.8986 14.7932Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M8.23078 16.3501C8.33146 16.8523 8.43216 17.3043 8.53284 17.8065C7.9791 18.0576 7.42536 18.3087 6.82129 18.5598C6.87163 17.6558 7.57637 16.9527 8.23078 16.3501Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M8.58283 17.6055C8.02909 18.8108 6.77061 19.6143 5.51211 19.1121C4.25362 18.6099 4.15293 17.2038 4.90803 16.0487C5.61279 14.9439 7.17332 14.1404 8.43182 13.6382C8.7842 14.9941 9.18691 16.2998 8.58283 17.6055Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M4.95867 14.8435C5.05936 15.3959 5.10972 15.9483 5.26074 16.5007C4.75732 16.7016 4.25391 16.9527 3.75049 17.1536C3.80083 16.2497 4.35457 15.4964 4.95867 14.8435Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M5.36131 16.35C4.9586 17.5552 3.75047 18.3588 2.54233 17.6557C1.33418 16.9526 1.28383 15.4962 2.08926 14.3411C2.84435 13.2363 4.20353 12.5332 5.41167 12.031C5.66337 13.4372 5.81437 14.994 5.36131 16.35Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M5.41162 12.0311C6.0157 11.78 6.72046 11.7298 7.32455 12.0311C7.92863 12.3324 8.28102 12.9852 8.43204 13.6883" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M2.4917 10.3237C3.09579 10.0726 3.75021 10.0224 4.30396 10.3739C4.90806 10.7254 5.26043 11.3783 5.36111 12.0311" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M7.57637 13.2363C7.72738 13.6883 7.82806 14.1905 7.92874 14.6425C7.37502 14.9439 6.8213 15.2452 6.26758 15.647C6.36826 15.0443 6.41857 14.4416 6.36823 13.7888C6.77094 13.5879 7.12332 13.387 7.57637 13.2363Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M6.87157 11.0769C6.51918 11.0267 6.16679 11.0267 5.86475 11.1272C6.01577 11.5791 6.11648 12.0813 6.2675 12.5333C6.51921 12.4831 6.77089 12.5333 6.97226 12.6338C6.92192 12.1316 6.87157 11.6293 6.87157 11.0769Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M48.1504 16.3503C48.5531 16.1494 48.9558 15.9485 49.4089 15.7979C49.7613 16.3 50.1137 16.752 50.5667 17.204C50.013 17.204 49.4592 17.2542 48.9055 17.4049C48.6034 17.0533 48.3517 16.752 48.1504 16.3503Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M50.063 18.6101C50.5664 18.4594 51.0698 18.2586 51.5732 18.1079C51.8752 18.6101 52.1773 19.1123 52.5296 19.5643C51.5732 19.6647 50.7677 19.1625 50.063 18.6101Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M51.3719 18.0578C52.6304 18.3591 53.6371 19.464 53.1841 20.8199C52.731 22.1759 51.4726 22.4269 50.2141 21.7741C49.0059 21.1714 48.0998 19.8155 47.395 18.6605C48.6535 18.108 50.0127 17.7063 51.3719 18.0578Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M49.1069 21.8242C49.6103 21.6736 50.1137 21.4727 50.6171 21.322C50.9192 21.8242 51.2212 22.3265 51.5736 22.7785C50.6171 22.8789 49.8117 22.3767 49.1069 21.8242Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M50.4153 21.2718C51.6738 21.5732 52.6806 22.678 52.2275 23.9838C51.7745 25.2895 50.4153 25.6411 49.2072 24.938C48.0493 24.2851 47.1432 22.9794 46.4385 21.8243C47.697 21.3221 49.0058 20.9203 50.4153 21.2718Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M46.3884 21.8745C46.036 21.3221 45.8347 20.619 46.0361 19.9661C46.2374 19.3133 46.7408 18.9115 47.3952 18.6604" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M45.0795 25.3397C44.7271 24.6868 44.7272 23.8833 45.0292 23.2305C45.2809 22.5776 45.7843 22.1256 46.4387 21.8745" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M47.1938 19.5644C47.5966 19.3635 47.9993 19.1626 48.4524 19.012C48.8047 19.5142 49.1571 19.9662 49.6102 20.4182C49.0564 20.4182 48.5027 20.4684 47.9489 20.6191C47.6469 20.2675 47.3952 19.916 47.1938 19.5644Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M45.2808 20.5186C45.2808 20.8702 45.3814 21.2217 45.4821 21.5231C45.8848 21.3222 46.3379 21.1715 46.7406 20.9706C46.6399 20.7195 46.6399 20.4684 46.6902 20.2173C46.2372 20.3177 45.7338 20.4182 45.2808 20.5186Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M46.1869 22.7282C46.5896 22.5273 46.9923 22.3265 47.4454 22.1758C47.7978 22.678 48.1501 23.13 48.6032 23.582C48.0495 23.582 47.4957 23.6322 46.942 23.7829C46.64 23.4313 46.3883 23.0798 46.1869 22.7282Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M48.0996 24.9883C48.603 24.8376 49.1064 24.6367 49.6098 24.4861C49.9118 24.9883 50.2139 25.4905 50.5663 25.9425C49.6098 26.0429 48.8044 25.5407 48.0996 24.9883Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M49.4085 24.436C50.667 24.7373 51.6738 25.8422 51.2207 27.1981C50.7677 28.5541 49.5092 28.8051 48.2507 28.1523C47.0425 27.5496 46.1364 26.1937 45.4316 25.0386C46.6901 24.4862 48.0493 24.0844 49.4085 24.436Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M47.0933 28.2024C47.5967 28.0517 48.1001 27.8509 48.6035 27.7002C48.9055 28.2024 49.2076 28.7046 49.5599 29.1566C48.6035 29.257 47.8484 28.7548 47.0933 28.2024Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M48.4524 27.5996C49.7109 27.9009 50.7177 29.0057 50.2646 30.3114C49.8116 31.6172 48.4524 31.9687 47.2443 31.2656C46.0864 30.6128 45.1803 29.3071 44.4756 28.152C45.6837 27.7 47.0429 27.2983 48.4524 27.5996Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M44.425 28.2024C44.0727 27.65 43.8713 26.9469 44.0727 26.294C44.274 25.6411 44.7774 25.2394 45.4318 24.9883" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M43.0659 31.6676C42.7135 31.0147 42.7135 30.2112 43.0155 29.5583C43.2672 28.9055 43.7706 28.4535 44.425 28.2024" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M45.1802 25.9423C45.5829 25.7414 45.9856 25.5406 46.4387 25.3899C46.791 25.8921 47.1434 26.3441 47.5965 26.796C47.0427 26.796 46.489 26.8463 45.9353 26.9969C45.6332 26.6454 45.4319 26.2939 45.1802 25.9423Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M43.2671 26.8463C43.2671 27.1978 43.3678 27.5494 43.4684 27.8507C43.8712 27.6498 44.3242 27.4991 44.7269 27.2982C44.6262 27.0471 44.6263 26.796 44.6766 26.5449C44.2235 26.6956 43.7705 26.7458 43.2671 26.8463Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M44.1729 29.1564C44.5756 28.9555 45.0286 28.7547 45.4314 28.604C45.7837 29.1062 46.1361 29.5582 46.5892 30.0101C46.0354 30.0101 45.4313 30.0604 44.8776 30.211C44.6762 29.8093 44.4246 29.5079 44.1729 29.1564Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M46.0356 31.4665C46.5894 31.2656 47.0928 31.1149 47.6465 30.9141C47.8982 31.4163 48.1499 31.9185 48.4016 32.4207C47.4451 32.4709 46.6901 32.0189 46.0356 31.4665Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M47.3947 30.8136C48.7538 31.1651 49.4083 32.3202 48.8545 33.6259C48.3008 34.9316 46.8409 35.484 45.5824 34.8312C44.3743 34.2285 43.6192 32.8726 43.0654 31.6673C44.4246 31.0647 45.9348 30.4621 47.3947 30.8136Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M44.3745 35.0824C44.9283 34.8314 45.5324 34.6305 46.0861 34.3794C46.3379 34.8816 46.5896 35.3838 46.8413 35.886C45.8344 36.0366 45.0793 35.6349 44.3745 35.0824Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M45.8342 34.2788C47.1934 34.5299 47.8981 35.685 47.3444 36.9907C46.7906 38.2965 45.4818 38.9995 44.1226 38.4471C42.8138 37.9449 42.0587 36.6392 41.4546 35.4339C42.7634 34.6806 44.324 34.0277 45.8342 34.2788Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M41.4545 35.4843C41.1021 34.7812 41.1022 34.0279 41.4042 33.2746C41.7062 32.5213 42.3103 31.9689 43.0654 31.7178" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M40.0955 39.4515C39.6927 38.7986 39.5921 37.9951 39.8438 37.2418C40.0955 36.4885 40.6996 35.8357 41.4547 35.4841" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M42.6626 32.7219C43.1157 32.4708 43.619 32.2197 44.1224 32.0188C44.4245 32.521 44.7265 33.0232 45.1292 33.4752C44.4748 33.5255 43.8708 33.6761 43.2667 33.877C43.0653 33.4752 42.864 33.1237 42.6626 32.7219Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M40.4481 34.0278C40.3978 34.4296 40.3978 34.8314 40.4985 35.2332C41.0019 34.9318 41.4549 34.6807 41.9583 34.3794C41.908 34.0781 41.9583 33.827 42.059 33.5256C41.5052 33.6763 40.9515 33.827 40.4481 34.0278Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M41.1025 36.589C41.5556 36.2877 42.0087 35.9864 42.5121 35.7354C42.8141 36.2375 43.1665 36.7397 43.5692 37.1414C42.9651 37.2419 42.3611 37.4427 41.757 37.6436C41.5053 37.3423 41.2536 36.9908 41.1025 36.589Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M42.9146 38.7988C43.4683 38.4975 44.022 38.2464 44.5758 37.9451C44.8275 38.4473 45.1798 38.8993 45.4819 39.4014C44.5254 39.6525 43.6696 39.301 42.9146 38.7988Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M44.3745 37.9451C45.7337 38.0957 46.5392 39.1504 46.1868 40.5063C45.7841 41.8622 44.6263 42.6155 43.1664 42.2137C41.8072 41.8622 40.9011 40.6067 40.146 39.4517C41.3038 38.5979 42.814 37.7944 44.3745 37.9451Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M41.9585 42.6656C42.4619 42.3643 42.9653 42.0127 43.4687 41.7114C43.821 42.1634 44.1734 42.6154 44.5761 43.0171C43.67 43.3184 42.8143 43.0673 41.9585 42.6656Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M43.2673 41.6611C44.6264 41.6611 45.5829 42.6655 45.4319 44.0717C45.2305 45.4778 44.2237 46.2311 42.7639 45.9298C41.3544 45.6787 40.2469 44.6241 39.3408 43.5193C40.4483 42.5651 41.7571 41.6611 43.2673 41.6611Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M39.2903 43.5194C38.7869 42.9168 38.5855 42.1635 38.7366 41.36C38.8876 40.5565 39.3909 39.9036 40.0957 39.4517" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M39.0388 47.3864C38.485 46.834 38.1327 46.1811 38.183 45.3776C38.2333 44.5741 38.6864 43.9212 39.2905 43.4692" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M39.8945 40.6065C40.2972 40.255 40.7503 39.9034 41.2033 39.6523C41.5557 40.1043 41.9584 40.5563 42.4115 40.9581C41.8074 41.1087 41.2537 41.3096 40.6999 41.6612C40.3476 41.3096 40.0959 40.9581 39.8945 40.6065Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M37.9814 42.2639C38.0318 42.6656 38.1324 43.0172 38.3338 43.4189C38.7365 43.0674 39.1393 42.7158 39.5923 42.4145C39.4413 42.1634 39.4413 41.8621 39.4917 41.5608C38.9379 41.8119 38.4848 42.063 37.9814 42.2639Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M39.2397 44.6243C39.5921 44.2728 39.9948 43.9212 40.3975 43.6199C40.8002 44.0216 41.2533 44.4737 41.7567 44.775C41.203 44.9759 40.6492 45.227 40.1458 45.5283C39.7935 45.2772 39.4914 44.9759 39.2397 44.6243Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M41.606 46.3821C42.059 46.0305 42.5121 45.7292 42.9651 45.3777C43.3679 45.7794 43.8209 46.1812 44.2236 46.583C43.3679 46.9847 42.4617 46.7336 41.606 46.3821Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M42.7637 45.3776C44.1229 45.2772 45.2304 46.1309 45.18 47.5873C45.1297 48.9935 44.0725 49.7468 42.6127 49.5961C41.2535 49.4455 39.995 48.3406 39.0386 47.3864C40.0957 46.382 41.2535 45.4781 42.7637 45.3776Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M41.606 50.1486C42.0087 49.7971 42.4617 49.4455 42.8645 49.094C43.2672 49.4455 43.7203 49.7971 44.1733 50.1486C43.3679 50.5002 42.4617 50.3495 41.606 50.1486Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M42.663 49.094C43.9215 48.9434 45.18 49.5962 45.18 50.9522C45.18 52.3081 44.1229 52.961 42.7134 52.9108C41.4045 52.8605 40.0454 52.1073 39.0386 51.2033C40.0454 50.1487 41.3039 49.2447 42.663 49.094Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M39.0385 51.1529C38.4848 50.7009 38.082 50.0983 38.082 49.2948C38.082 48.4913 38.4344 47.8384 39.0385 47.3865" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M39.2901 54.819C38.686 54.4172 38.2833 53.8146 38.233 53.1115C38.1826 52.3582 38.4847 51.7053 39.0384 51.2031" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M39.0889 48.4913C39.4412 48.0895 39.7936 47.738 40.1963 47.3865C40.6494 47.7882 41.1528 48.1398 41.6562 48.4411C41.1025 48.6921 40.5487 48.9432 40.0453 49.2948C39.693 49.0939 39.3909 48.7926 39.0889 48.4913Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M37.478 50.249C37.629 50.6005 37.7801 50.9019 38.0318 51.2032C38.3841 50.8517 38.7365 50.4499 39.0889 50.0984C38.8875 49.8975 38.8372 49.6464 38.7869 49.3953C38.3841 49.6966 37.9311 49.9477 37.478 50.249Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M39.1392 52.2075C39.4412 51.856 39.7936 51.5044 40.1459 51.2031C40.599 51.5547 41.1527 51.8057 41.6561 52.0066C41.1024 52.2577 40.599 52.559 40.0956 52.9106C39.7936 52.6595 39.4412 52.4586 39.1392 52.2075Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M41.6562 53.463C42.059 53.0612 42.512 52.7097 42.9651 52.3582C43.4182 52.6595 43.8209 53.011 44.274 53.3123C43.4685 53.7643 42.512 53.6639 41.6562 53.463Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M42.7136 52.4085C43.9218 52.2076 45.1803 52.76 45.2809 54.0155C45.3816 55.271 44.4755 56.0243 43.1667 56.1749C41.9082 56.2754 40.398 55.5221 39.3408 54.819C40.0456 53.6138 41.4048 52.6093 42.7136 52.4085Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M42.2603 56.7777C42.6126 56.3759 42.965 56.0243 43.3174 55.6226C43.8208 55.8234 44.2738 56.0746 44.7772 56.3257C44.0221 56.8279 43.116 56.8781 42.2603 56.7777Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M43.0657 55.6728C44.2235 55.271 45.5323 55.6226 45.8847 56.8279C46.2371 58.0331 45.482 58.8869 44.1732 59.138C42.965 59.3891 41.3038 58.9371 40.146 58.2842C40.8004 57.0789 41.8072 56.1247 43.0657 55.6728Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M40.1462 58.3344C39.4918 58.0331 38.938 57.4807 38.787 56.7274C38.636 56.0243 38.8877 55.3714 39.2904 54.7688" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M41.6058 61.649C40.9011 61.4481 40.2467 60.9459 39.9447 60.2428C39.6426 59.5397 39.7433 58.8868 40.146 58.2842" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M39.542 55.7731C39.7937 55.3713 40.0454 54.9695 40.3474 54.6682C40.9012 54.9193 41.4046 55.2206 41.9583 55.4215C41.4549 55.6726 41.0018 55.974 40.5991 56.3757C40.2467 56.1749 39.8944 55.974 39.542 55.7731Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M38.4346 57.6813C38.6359 57.9827 38.8877 58.284 39.1897 58.5351C39.3911 58.1333 39.6428 57.6814 39.8441 57.2796C39.6428 57.1289 39.5421 56.8778 39.4414 56.6267C39.1394 56.9783 38.7366 57.3298 38.4346 57.6813Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M40.5488 59.2382C40.7502 58.8365 40.9515 58.4347 41.2032 58.033C41.757 58.2338 42.3107 58.3845 42.8644 58.4849C42.4114 58.8365 42.059 59.188 41.7066 59.64C41.3039 59.5898 40.9012 59.4391 40.5488 59.2382Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M43.418 59.8913C43.6697 59.4895 43.871 59.0375 44.1731 58.6357C44.7268 58.8366 45.2805 58.9371 45.8343 59.0878C45.1295 59.6904 44.3744 59.8913 43.418 59.8913Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M44.0223 58.6859C45.0794 58.2339 46.5392 58.3343 47.1433 59.4392C47.7474 60.544 47.1937 61.4982 45.8848 61.9502C44.6767 62.352 42.8645 62.1511 41.606 61.6489C41.9583 60.4436 42.8141 59.1881 44.0223 58.6859Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M45.2808 62.8041C45.4318 62.3521 45.6331 61.8499 45.7841 61.3979C46.3882 61.4984 46.9923 61.5988 47.5963 61.649C47.0929 62.4023 46.1365 62.6534 45.2808 62.8041Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M45.6331 61.4981C46.6902 60.8453 48.15 60.795 49.0058 61.8497C49.8616 62.9043 49.4589 63.959 48.15 64.5616C46.8915 65.1141 45.18 65.1643 43.7705 64.7625C44.0222 63.507 44.4752 62.2515 45.6331 61.4981Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M43.7708 64.8129C43.0156 64.6622 42.2605 64.3107 41.8075 63.6578C41.3544 63.0049 41.3544 62.352 41.6061 61.6489" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M46.1365 67.7757C45.3814 67.7255 44.6264 67.4241 44.1733 66.8215C43.7203 66.2189 43.5693 65.5158 43.7706 64.8127" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M42.21 62.5528C42.3106 62.1008 42.4113 61.6488 42.5623 61.2471C43.1664 61.3475 43.7705 61.4982 44.4249 61.4982C44.0725 61.8999 43.7201 62.3017 43.5188 62.8039C43.0657 62.7537 42.6127 62.6532 42.21 62.5528Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M41.8071 64.7122C42.1595 64.9633 42.4615 65.1642 42.8642 65.3149C42.9649 64.8629 43.0656 64.3607 43.1159 63.9087C42.8642 63.8083 42.6126 63.6074 42.4112 63.4065C42.2602 63.8585 42.0588 64.2602 41.8071 64.7122Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M44.4751 65.6163C44.5254 65.1643 44.5758 64.7123 44.7268 64.3105C45.3309 64.3608 45.9853 64.411 46.5894 64.3105C46.2873 64.7625 46.0356 65.2145 45.7839 65.7167C45.3309 65.767 44.9282 65.767 44.4751 65.6163Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M47.5962 65.516C47.7472 65.064 47.8982 64.5618 47.9989 64.1099C48.603 64.1601 49.2071 64.1601 49.8615 64.2103C49.3581 64.9636 48.452 65.2649 47.5962 65.516Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M47.7975 64.21C48.8043 63.4065 50.3648 63.2558 51.2206 64.2603C52.1267 65.3149 51.5729 66.5704 50.3648 67.2735C49.207 67.9263 47.4451 67.9263 46.1362 67.7757C46.4383 66.4197 46.69 65.114 47.7975 64.21Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M49.7607 68.3283C49.9621 67.7758 50.1131 67.2736 50.2641 66.7212C50.8179 66.7714 51.3716 66.8216 51.9254 66.8216C51.4723 67.6252 50.6669 68.027 49.7607 68.3283Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M50.1132 66.8216C51.0193 65.9177 52.4792 65.767 53.2342 66.9221C53.9893 68.0771 53.3349 69.4331 52.1268 70.0859C50.969 70.7388 49.4084 70.7388 48.0996 70.5881C48.5023 69.2824 49.1064 67.826 50.1132 66.8216Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M48.0996 70.6383C47.4452 70.5881 46.7908 70.3369 46.3881 69.7343C45.9854 69.1316 45.9854 68.4285 46.1364 67.7756" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M49.912 73.501C49.2576 73.4508 48.6535 73.1997 48.3011 72.597C47.9488 71.9944 47.9487 71.2913 48.0998 70.6384" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M46.7407 68.5794C46.8414 68.0771 46.9924 67.6251 47.0931 67.1229C47.7475 67.1229 48.3516 67.1229 49.006 67.0225C48.6536 67.5247 48.3516 68.0269 48.0496 68.5794C47.5965 68.6296 47.1938 68.6296 46.7407 68.5794Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M46.3882 70.789C46.6902 70.9899 46.9923 71.1405 47.2943 71.2409C47.395 70.7387 47.4957 70.2868 47.5963 69.7846C47.3446 69.7343 47.1433 69.5837 46.9923 69.3828C46.7909 69.8348 46.5895 70.337 46.3882 70.789Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M56.4562 60.2929L4.60623 30.0603L4.3042 44.7749L57.0603 68.2277L56.4562 60.2929Z" fill="#FFBB24" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M10.8481 4.59863L30.7324 16.9026C33.5011 18.6101 37.0752 18.0577 39.1895 15.6471L48.2506 5.15104" stroke="#B2C5D3" stroke-width="8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path d="M66.625 36.589C65.6686 38.3467 63.4536 38.9494 61.7421 37.9952L58.621 36.2877C54.9966 34.2789 52.6809 30.4622 52.6306 26.3441L52.5803 22.8789C52.5299 20.8701 54.1408 19.263 56.104 19.2128C58.1176 19.1626 59.7285 20.7696 59.7788 22.7282L59.8292 26.1935C59.8795 27.7503 60.7353 29.2067 62.0945 30.0102L65.2155 31.7177C66.9774 32.6719 67.6318 34.8815 66.625 36.589Z" fill="#FF7171" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
    <path opacity="0.37" d="M56.6072 30.3113C54.8957 27.9007 55.6004 19.3131 55.6004 19.2126C53.8385 19.4637 52.5297 21.0206 52.58 22.8285L52.6303 26.2937C52.6807 30.4117 54.9963 34.2285 58.6208 36.2373L61.7418 37.9448C63.5037 38.899 65.6684 38.2963 66.6248 36.5386C66.6248 36.5888 58.3188 32.7219 56.6072 30.3113Z" fill="#F26161" data-sentry-element="path" data-sentry-source-file="wirings.tsx" />
  </svg>;