import type { SVGProps } from "react";
export const ChibiShocked = (props: SVGProps<SVGSVGElement>) => <svg width="82" height="91" viewBox="0 0 82 91" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="ChibiShocked" data-sentry-source-file="chibi-shocked.tsx">
    <path opacity="0.12" d="M44.1861 90.8296C59.8942 90.8296 72.6281 83.2748 72.6281 73.9556C72.6281 64.6363 59.8942 57.0815 44.1861 57.0815C28.4781 57.0815 15.7441 64.6363 15.7441 73.9556C15.7441 83.2748 28.4781 90.8296 44.1861 90.8296Z" fill="#808080" data-sentry-element="path" data-sentry-source-file="chibi-shocked.tsx" />
    <path d="M54.1532 64.7151C55.16 63.3089 57.3246 67.2261 57.5763 67.8789C58.3314 69.5362 58.6335 71.294 58.5831 73.1019C58.5831 74.0561 59.7913 74.9098 60.647 74.3072C61.8048 73.4534 62.9627 72.6499 64.0198 71.6957C65.3286 70.5407 63.4157 68.582 62.0566 69.7371C61.1505 70.5406 60.194 71.2437 59.2375 71.9468C59.9423 72.3486 60.5967 72.7503 61.3015 73.1521C61.3518 69.7371 60.2947 66.1212 57.979 63.56C56.2171 61.6516 53.3981 61.0992 51.7873 63.4093C50.7805 64.8155 53.1464 66.1715 54.1532 64.7151Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-shocked.tsx" />
    <path d="M59.3383 55.0224C61.5532 55.5748 63.7682 56.1273 65.9831 56.6797C68.5504 57.3326 71.2184 57.1819 71.4701 53.8674C71.6211 52.1096 68.8525 52.1096 68.7518 53.8674C68.6511 55.0224 64.6743 53.5158 64.1709 53.3652C62.8117 53.0136 61.4525 52.6621 60.0934 52.3607C58.3818 51.959 57.6267 54.6207 59.3383 55.0224Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-shocked.tsx" />
    <path d="M40.4107 58.8893C40.0583 63.2585 39.4039 68.7325 35.6284 71.6453C34.4202 72.5493 32.6583 72.8004 31.6012 73.7044C29.8896 75.1105 31.5508 76.818 32.5576 78.0233C33.6651 79.3793 35.6284 77.4206 34.5209 76.0647C34.2692 75.3114 33.9672 75.211 33.5644 75.6629C33.061 75.9643 33.061 76.0145 33.5644 75.7634C33.8665 75.7132 34.1685 75.5123 34.4706 75.3616C35.1753 75.0603 35.8297 74.7087 36.4842 74.307C37.6923 73.5035 38.6991 72.5493 39.5549 71.3942C42.1726 67.8286 42.7766 63.1078 43.129 58.8391C43.3304 57.1316 40.5617 57.1316 40.4107 58.8893Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-shocked.tsx" />
    <path opacity="0.15" d="M64.2214 28.1547C64.8886 28.1547 65.4295 27.6151 65.4295 26.9494C65.4295 26.2838 64.8886 25.7441 64.2214 25.7441C63.5541 25.7441 63.0132 26.2838 63.0132 26.9494C63.0132 27.6151 63.5541 28.1547 64.2214 28.1547Z" fill="white" data-sentry-element="path" data-sentry-source-file="chibi-shocked.tsx" />
    <path d="M3.96476 36.8929C6.17971 41.8145 14.6368 32.0215 19.6205 29.9625C24.6544 27.9035 27.0204 22.1784 24.9565 17.1564C22.8925 12.1343 17.1538 9.77399 12.1198 11.833C7.13618 13.9423 -2.47874 22.4295 3.96476 36.8929Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-shocked.tsx" />
    <path opacity="0.15" d="M6.73366 18.5122C4.31735 22.3792 4.51871 26.7985 5.67652 27.5518C6.88468 28.3051 9.80438 25.7941 12.1703 21.9272C14.5363 18.0602 15.5431 14.344 14.335 13.6409C13.1771 12.8876 9.09963 14.6955 6.73366 18.5122Z" fill="white" data-sentry-element="path" data-sentry-source-file="chibi-shocked.tsx" />
    <path d="M60.9381 60.2678C72.0256 49.2066 72.0256 31.2729 60.9381 20.2117C49.8506 9.1505 31.8742 9.15052 20.7867 20.2117C9.69914 31.2729 9.69914 49.2066 20.7867 60.2678C31.8742 71.329 49.8506 71.329 60.9381 60.2678Z" fill="#FDDA44" data-sentry-element="path" data-sentry-source-file="chibi-shocked.tsx" />
    <path d="M19.1166 32.775C15.3915 42.2165 17.5561 51.9089 20.4758 53.064C23.3955 54.2191 28.8322 47.4896 32.507 38.0984C36.2322 28.657 36.8362 20.0693 33.9165 18.9142C30.9968 17.7592 22.8418 23.3336 19.1166 32.775Z" fill="#FDE272" data-sentry-element="path" data-sentry-source-file="chibi-shocked.tsx" />
    <path d="M64.6481 35.1842C64.8034 33.1098 63.8731 31.3495 62.57 31.2523C61.267 31.1552 60.0847 32.7581 59.9293 34.8324C59.7739 36.9068 60.7043 38.6671 62.0073 38.7643C63.3104 38.8614 64.4927 37.2586 64.6481 35.1842Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-shocked.tsx" />
    <path d="M51.8878 49.9C52.1395 51.9089 54.4047 53.1644 56.368 51.8084C59.1367 49.9503 59.3884 48.1423 58.8347 46.1837C58.2306 44.2251 56.066 44.3758 54.8075 44.9282C53.549 45.5308 51.6361 47.8912 51.8878 49.9Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-shocked.tsx" />
    <path d="M43.8661 49.9928C45.1279 48.1656 44.9443 45.8552 43.4562 44.8325C41.968 43.8097 39.7388 44.4619 38.477 46.2891C37.2152 48.1164 37.3988 50.4268 38.8869 51.4495C40.3751 52.4722 42.6044 51.8201 43.8661 49.9928Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-shocked.tsx" />
    <path d="M26.366 18.663C28.5345 18.663 30.2925 16.9092 30.2925 14.7458C30.2925 12.5824 28.5345 10.8286 26.366 10.8286C24.1974 10.8286 22.4395 12.5824 22.4395 14.7458C22.4395 16.9092 24.1974 18.663 26.366 18.663Z" fill="#F24444" data-sentry-element="path" data-sentry-source-file="chibi-shocked.tsx" />
    <path opacity="0.15" d="M25.0069 15.5995C25.9244 15.5995 26.6682 14.8575 26.6682 13.9422C26.6682 13.0269 25.9244 12.2849 25.0069 12.2849C24.0895 12.2849 23.3457 13.0269 23.3457 13.9422C23.3457 14.8575 24.0895 15.5995 25.0069 15.5995Z" fill="white" data-sentry-element="path" data-sentry-source-file="chibi-shocked.tsx" />
    <path d="M20.7278 22.6306C22.8963 22.6306 24.6543 20.8768 24.6543 18.7134C24.6543 16.55 22.8963 14.7961 20.7278 14.7961C18.5592 14.7961 16.8013 16.55 16.8013 18.7134C16.8013 20.8768 18.5592 22.6306 20.7278 22.6306Z" fill="#F24444" data-sentry-element="path" data-sentry-source-file="chibi-shocked.tsx" />
    <path opacity="0.15" d="M19.3687 19.5672C20.2862 19.5672 21.03 18.8252 21.03 17.9099C21.03 16.9947 20.2862 16.2527 19.3687 16.2527C18.4513 16.2527 17.7075 16.9947 17.7075 17.9099C17.7075 18.8252 18.4513 19.5672 19.3687 19.5672Z" fill="white" data-sentry-element="path" data-sentry-source-file="chibi-shocked.tsx" />
    <path d="M34.8731 40.4586C34.8731 40.5089 34.7221 40.8604 34.7724 40.7599C34.7221 40.9106 34.6214 41.1115 34.5207 41.2621C34.5207 41.2621 34.3697 41.463 34.42 41.4128C34.4704 41.3626 34.3193 41.5132 34.3193 41.5132C34.269 41.5635 34.2187 41.6137 34.1683 41.7141C34.1683 41.7141 33.967 41.915 34.0676 41.8146C33.5643 42.2163 33.2622 42.8692 33.6146 43.4718C33.9166 43.974 34.7221 44.2753 35.2758 43.9238C36.2323 43.2207 36.8363 42.3168 37.1887 41.2119C37.3901 40.6093 36.937 39.9062 36.3329 39.7555C35.7288 39.4542 35.0744 39.856 34.8731 40.4586Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-shocked.tsx" />
    <path d="M62.4089 25.5432C61.7545 25.5432 61.2008 25.3925 60.6974 25.041C60.194 24.6392 59.3382 24.9405 59.0362 25.493C58.6838 26.0956 58.9355 26.7485 59.4892 27.1502C60.345 27.7529 61.4021 28.004 62.4089 28.004C63.9694 27.9538 63.9694 25.5432 62.4089 25.5432Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-shocked.tsx" />
    <path opacity="0.4" d="M59.3888 22.2788C58.6337 22.5802 57.9793 22.9819 57.3249 23.4339L37.189 36.0392C32.1047 38.1987 28.8829 44.2251 29.9904 49.4982C31.1482 54.8215 36.0815 57.0814 41.3169 55.2233C46.9549 53.2145 48.5154 46.4347 48.9181 43.4215C49.0692 42.4171 49.5726 41.5132 50.378 40.8603L50.9317 40.4083C52.3412 39.655 54.0025 39.7555 55.261 40.7097C57.4256 42.3669 60.4963 42.7687 63.5167 41.5132C68.601 39.3537 71.8227 33.3273 70.7152 28.0542C69.5574 22.7308 64.4731 20.1696 59.3888 22.2788Z" fill="#52DEFF" data-sentry-element="path" data-sentry-source-file="chibi-shocked.tsx" />
    <path d="M30.0408 60.8983C31.6013 63.9618 36.585 66.6737 40.0584 66.3221C41.4176 66.1715 42.5754 65.3177 43.1291 64.0622C43.7835 62.3547 43.6325 59.1406 45.7468 58.3873C47.408 57.7847 46.7032 55.1732 45.042 55.7256C43.5318 56.2278 42.5251 57.4834 41.8203 58.8895C41.4176 59.7433 41.0652 60.6472 40.8135 61.5512C40.7128 61.9027 40.7632 62.7062 40.5618 63.0076C39.102 65.3679 33.2625 61.1494 32.4068 59.4921C31.6013 57.9855 29.2354 59.3415 30.0408 60.8983Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-shocked.tsx" />
    <path d="M71.4702 53.9176C71.3192 51.1555 71.4199 45.7819 68.2485 44.5766C66.5873 43.974 65.8825 46.5854 67.5437 47.2383C67.8961 47.389 68.2485 49.4982 68.3492 49.9502C68.6009 51.2559 68.7016 52.6118 68.7519 53.9176C68.8526 55.6753 71.5709 55.6753 71.4702 53.9176Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-shocked.tsx" />
  </svg>;