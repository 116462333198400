import type { SVGProps } from "react";
export const ChibiInvestigating = (props: SVGProps<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" width="75" height="82" viewBox="0 0 75 82" fill="none" {...props} data-sentry-element="svg" data-sentry-component="ChibiInvestigating" data-sentry-source-file="chibi-investigating.tsx">
    <path opacity="0.12" d="M32.0661 81.5228C47.2459 81.5228 59.5515 74.2154 59.5515 65.2012C59.5515 56.1871 47.2459 48.8796 32.0661 48.8796C16.8862 48.8796 4.58057 56.1871 4.58057 65.2012C4.58057 74.2154 16.8862 81.5228 32.0661 81.5228Z" fill="#808080" data-sentry-element="path" data-sentry-source-file="chibi-investigating.tsx" />
    <path d="M52.7554 33.6631C56.2289 34.567 59.7527 35.4207 63.2261 36.3247C64.9376 36.7767 65.6927 34.115 63.9308 33.6631C60.4574 32.7591 56.9336 31.9053 53.4602 31.0014C51.799 30.5996 51.0439 33.2613 52.7554 33.6631Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-investigating.tsx" />
    <path d="M40.2715 41.196C39.8184 45.5652 39.6171 49.9845 39.7178 54.3537C39.7681 55.8603 39.4157 58.1704 40.1205 59.6268C40.7749 60.9325 42.2851 60.8823 43.5939 61.0832C45.3055 61.3343 46.0606 58.7228 44.2987 58.4215C43.4429 58.2708 42.5871 58.2206 41.7817 57.9193C42.0837 58.2206 42.4361 58.5722 42.7382 58.8735C41.9831 56.2118 42.3858 52.6964 42.4361 49.9343C42.4864 47.0215 42.6878 44.0585 42.9899 41.1457C43.1912 39.4382 40.4225 39.4383 40.2715 41.196Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-investigating.tsx" />
    <path d="M19.9845 42.4013C19.9845 47.574 19.9845 52.6965 19.9845 57.8692C19.9845 60.0789 19.5818 62.841 20.0852 65.0004C20.4879 66.6577 21.8471 66.7582 23.3573 67.0595C25.0688 67.4111 25.8239 64.7996 24.062 64.3978C22.0484 63.996 22.7532 61.4851 22.7532 59.7776C22.7532 57.8692 22.7532 55.9106 22.7532 54.0022C22.7532 50.1353 22.7532 46.2683 22.7532 42.4013C22.7532 40.6436 19.9845 40.6436 19.9845 42.4013Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-investigating.tsx" />
    <path d="M28.8945 56.8144C44.5748 56.8144 57.2861 44.1332 57.2861 28.4902C57.2861 12.8472 44.5748 0.166016 28.8945 0.166016C13.2143 0.166016 0.50293 12.8472 0.50293 28.4902C0.50293 44.1332 13.2143 56.8144 28.8945 56.8144Z" fill="#FDDA44" data-sentry-element="path" data-sentry-source-file="chibi-investigating.tsx" />
    <path d="M10.2186 15.1818C3.97642 23.1668 3.22134 33.0602 5.73834 35.0188C8.205 36.9774 15.3029 32.0558 21.5451 24.0708C27.7872 16.0858 30.858 8.05054 28.3913 6.09195C25.9246 4.13335 16.5111 7.19678 10.2186 15.1818Z" fill="#FDE272" data-sentry-element="path" data-sentry-source-file="chibi-investigating.tsx" />
    <path d="M53.2166 30.4381C53.9583 28.494 53.5695 26.5421 52.3483 26.0784C51.127 25.6147 49.5358 26.8149 48.7941 28.759C48.0524 30.7031 48.4412 32.6549 49.6624 33.1186C50.8836 33.5823 52.4749 32.3822 53.2166 30.4381Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-investigating.tsx" />
    <path d="M28.658 40.0475C30.3895 38.6547 30.8736 36.3879 29.7392 34.9844C28.6049 33.5809 26.2817 33.5722 24.5502 34.965C22.8187 36.3578 22.3346 38.6246 23.4689 40.0281C24.6033 41.4316 26.9265 41.4402 28.658 40.0475Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-investigating.tsx" />
    <path d="M38.4086 39.9404C38.761 38.0822 40.1202 38.2831 41.6807 37.9316C43.4426 37.5298 44.7011 36.5254 45.4562 34.9183C46.0603 33.5121 43.9964 32.3068 43.3923 33.713C42.5365 35.6716 41.0767 35.5209 39.2644 35.9729C37.5025 36.4249 36.4454 37.58 36.093 39.3377C35.791 40.7941 38.1066 41.447 38.4086 39.9404Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-investigating.tsx" />
    <path d="M23.4073 31.3526C26.1257 32.0556 28.5923 30.1473 29.1461 27.4857C29.4984 25.9791 27.1828 25.3262 26.8304 26.8328C26.5284 28.1385 25.5216 29.394 24.0114 28.9923C22.5515 28.6407 21.9475 30.9508 23.4073 31.3526Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-investigating.tsx" />
    <path d="M44.6511 20.8568C46.3123 21.9114 48.2252 22.7149 50.2388 22.0118C51.6987 21.5096 51.0443 19.1995 49.5844 19.7017C48.2252 20.1537 47.0171 19.5008 45.8593 18.7475C44.5504 17.9439 43.3423 20.003 44.6511 20.8568Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-investigating.tsx" />
    <path d="M64.7363 38.032C64.1323 38.032 63.6792 37.5801 63.6792 36.9774V28.4399H66.9513V35.7721C66.9513 37.0779 65.9445 38.032 64.7363 38.032Z" fill="#F93E3E" data-sentry-element="path" data-sentry-source-file="chibi-investigating.tsx" />
    <path d="M66.0452 12.269L65.3908 12.6206C61.0616 15.1316 58.3936 19.7519 58.3936 24.7237V25.477C58.3936 29.6453 62.9242 32.2568 66.4983 30.1475C71.2302 27.4356 74.1499 22.3633 74.1499 16.9395C74.1499 12.7712 69.6193 10.1597 66.0452 12.269Z" fill="#D5D9E2" data-sentry-element="path" data-sentry-source-file="chibi-investigating.tsx" />
    <path d="M66.0958 15.5836L65.6931 15.8347C62.9747 17.3916 61.2632 20.3043 61.2632 23.4682V23.9202C61.2632 26.5316 64.0822 28.1889 66.3979 26.8832C69.3679 25.1757 71.2305 22.0118 71.2305 18.5466C71.2305 15.8849 68.4114 14.2779 66.0958 15.5836Z" fill="#35C3EF" data-sentry-element="path" data-sentry-source-file="chibi-investigating.tsx" />
    <path d="M65.7434 27.1342C68.3107 25.3263 69.8712 22.4135 69.8712 19.2497C69.8712 16.8391 67.5053 15.2823 65.3406 16.0356C62.8237 17.6426 61.3135 20.4047 61.3135 23.4179V23.8699C61.3135 26.2805 63.6291 27.8373 65.7434 27.1342Z" fill="#48DDFF" data-sentry-element="path" data-sentry-source-file="chibi-investigating.tsx" />
    <path d="M51.1447 48.9298L37.3012 46.3184L28.4414 65.3519L42.2849 67.9633L51.1447 48.9298Z" fill="#48DDFF" data-sentry-element="path" data-sentry-source-file="chibi-investigating.tsx" />
    <path d="M63.4782 41.4972L49.6348 38.8857L40.7246 57.9193L54.568 60.5308L63.4782 41.4972Z" fill="#48DDFF" data-sentry-element="path" data-sentry-source-file="chibi-investigating.tsx" />
    <path d="M49.6348 38.8857L51.145 48.9298L45.4565 47.8752L49.6348 38.8857Z" fill="#38B4F2" data-sentry-element="path" data-sentry-source-file="chibi-investigating.tsx" />
    <path d="M15.1016 47.3731C20.2866 53.5502 28.19 55.4586 35.7409 57.0656C37.4525 57.4172 38.2076 54.8057 36.4457 54.4039C29.3478 52.8973 21.8975 51.24 17.0145 45.4145C15.9071 44.1088 13.9942 46.0674 15.1016 47.3731Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-investigating.tsx" />
  </svg>;