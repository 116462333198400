import type { SVGProps } from "react";
export const PencilMechanical = (props: SVGProps<SVGSVGElement>) => <svg width="231" height="153" viewBox="0 0 231 153" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="PencilMechanical" data-sentry-source-file="pencil-mechanical.tsx">
    <g id="Group 5" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
      <g id="Group" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
        <path id="Vector" d="M75.2197 101.055L80.4047 112.505L106.481 97.4892L100.541 86.491L75.2197 101.055Z" fill="#B2B8C1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_2" d="M49.9491 86.4911L45.3179 95.0285L71.1925 80.314L75.2197 71.9272L49.9491 86.4911Z" fill="#B2B8C1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_3" d="M49.9491 86.4911L75.2197 71.9272L100.541 86.4911L75.2197 101.055L49.9491 86.4911Z" fill="#DAE1E5" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
      </g>
      <g id="Group_2" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
        <path id="Vector_4" d="M136.584 137.012L141.769 148.412L167.845 133.397L161.855 122.398L136.584 137.012Z" fill="#B2B8C1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_5" d="M111.263 122.398L106.632 130.986L132.507 116.221L136.584 107.834L111.263 122.398Z" fill="#B2B8C1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_6" d="M111.263 122.398L136.584 107.834L161.855 122.398L136.584 137.012L111.263 122.398Z" fill="#DAE1E5" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
      </g>
      <g id="Group_3" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
        <path id="Vector_7" d="M46.0524 43.4909C54.2703 29.392 54.1128 14.0066 45.7007 9.12661C37.2885 4.24662 23.8072 11.72 15.5893 25.8189C7.3714 39.9178 7.52887 55.3032 15.941 60.1832C24.3532 65.0632 37.8345 57.5898 46.0524 43.4909Z" fill="#FF5252" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_8" d="M174.2 152.03L203.96 100.973L45.6388 9.12927L15.8791 60.1858L174.2 152.03Z" fill="#FFBB24" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_9" d="M203.989 100.954L174.238 152.028L227.598 151.426C229.612 151.426 230.87 149.266 229.914 147.509L203.989 100.954Z" fill="#FFDBA6" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_10" d="M214.208 141.08C212.144 144.596 210.684 148.212 209.828 151.677L227.598 151.476C229.612 151.476 230.87 149.317 229.914 147.559L221.306 132.041C218.688 134.502 216.272 137.565 214.208 141.08Z" fill="#494851" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_11" d="M173.886 117.678C165.68 131.79 165.831 147.157 174.238 152.029L203.989 100.955C195.582 96.0832 182.091 103.566 173.886 117.678Z" fill="#FFDBA6" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_12" d="M33.6894 70.521L63.4402 19.447L45.6703 9.15186L23.9738 30.6963L15.9194 60.2258L33.6894 70.521Z" fill="#FF5252" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_13" d="M65.9503 55.038C74.1682 40.9392 74.0107 25.5538 65.5986 20.6738C57.1864 15.7938 43.7051 23.2672 35.4872 37.366C27.2693 51.4649 27.4268 66.8503 35.839 71.7303C44.2511 76.6103 57.7324 69.1369 65.9503 55.038Z" fill="#EBF0F7" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_14" d="M45.0046 76.8063L74.6377 25.9668L65.5423 20.6904L35.9091 71.5299L45.0046 76.8063Z" fill="#EBF0F7" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_15" d="M75.0261 60.3339C83.244 46.2351 83.0865 30.8497 74.6743 25.9697C66.2622 21.0897 52.7808 28.5631 44.5629 42.6619C36.345 56.7608 36.5025 72.1462 44.9147 77.0262C53.3268 81.9062 66.8082 74.4328 75.0261 60.3339Z" fill="#FFBB24" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_16" opacity="0.23" d="M181.336 106.529L53.02 32.0522" stroke="#C0C9D8" stroke-width="8.6789" stroke-miterlimit="10" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_17" opacity="0.23" d="M227.598 151.476C228.655 151.476 229.511 150.874 229.914 150.07L169.808 126.667L11.5399 34.7644C7.81471 45.9133 9.32491 56.3591 15.9698 60.1758L33.7397 70.471L174.288 152.029L209.879 151.627L227.598 151.476Z" fill="#C0C9D8" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
      </g>
      <g id="Group_4" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
        <path id="Vector_18" d="M129.235 114.263C129.235 114.263 128.681 96.7359 143.984 83.7289C121.331 71.0734 99.3831 58.6689 99.3831 58.6689C99.3831 58.6689 79.1465 66.0011 84.8349 87.8971C91.6308 92.2161 129.235 114.263 129.235 114.263Z" fill="#494851" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_19" d="M82.4185 76.949L133.06 105.474" stroke="#FF7171" stroke-width="8" stroke-miterlimit="10" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_20" d="M115.391 84.5322L135.728 96.0327" stroke="#9EAEB7" stroke-width="8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_21" d="M85.1367 67.4575L104.518 78.4055" stroke="#9EAEB7" stroke-width="8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <g id="Group_5" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
          <g id="Group_6" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
            <path id="Vector_22" d="M105.121 107.232C104.87 107.584 104.568 107.985 104.266 108.287C103.712 108.036 103.158 107.784 102.604 107.584C103.108 107.332 103.561 106.981 104.014 106.629C104.417 106.83 104.769 107.031 105.121 107.232Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_23" d="M102.403 106.127C102.051 106.529 101.648 106.88 101.296 107.282C100.792 106.981 100.289 106.679 99.7856 106.378C100.541 105.876 101.497 105.926 102.403 106.127Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_24" d="M101.447 107.232C100.188 107.533 98.7786 107.031 98.5772 105.625C98.3255 104.219 99.3827 103.415 100.792 103.415C102.151 103.415 103.561 104.168 104.719 104.871C103.863 105.926 102.856 106.88 101.447 107.232Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_25" d="M101.799 102.813C101.447 103.214 101.044 103.566 100.692 103.968C100.188 103.666 99.685 103.365 99.1816 103.064C99.9367 102.561 100.893 102.662 101.799 102.813Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_26" d="M100.843 103.917C99.5843 104.219 98.1748 103.666 97.9734 102.31C97.772 100.904 98.8292 100 100.188 100.05C101.497 100.101 102.907 100.854 104.065 101.507C103.259 102.612 102.252 103.566 100.843 103.917Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_27" d="M104.165 101.557C104.769 101.908 105.222 102.411 105.323 103.114C105.423 103.817 105.172 104.369 104.719 104.871" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_28" d="M103.763 97.8909C104.367 98.3429 104.719 98.9957 104.82 99.749C104.87 100.452 104.669 101.055 104.165 101.607" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_29" d="M104.517 103.968C104.266 104.319 103.964 104.721 103.662 105.022C103.108 104.771 102.554 104.52 102 104.319C102.504 104.068 102.957 103.717 103.41 103.365C103.813 103.516 104.165 103.717 104.517 103.968Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_30" d="M105.776 102.26C105.575 101.959 105.373 101.708 105.121 101.457C104.819 101.808 104.517 102.16 104.266 102.511C104.467 102.662 104.568 102.913 104.618 103.164C105.021 102.863 105.424 102.561 105.776 102.26Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          </g>
          <g id="Group_7" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
            <path id="Vector_31" d="M103.964 100.653C103.712 101.005 103.41 101.406 103.108 101.708C102.554 101.457 102.001 101.206 101.447 101.005C101.95 100.754 102.454 100.402 102.856 100.051C103.209 100.251 103.561 100.402 103.964 100.653Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_32" d="M101.246 99.3975C100.843 99.7992 100.49 100.201 100.088 100.603C99.6346 100.301 99.1816 99.9499 98.7285 99.5984C99.534 99.1464 100.39 99.1966 101.246 99.3975Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_33" d="M100.289 100.603C98.9297 100.904 97.8222 100.151 97.6712 98.7949C97.5705 97.3888 98.6276 96.2337 100.037 96.2337C101.396 96.1835 102.705 97.0874 103.712 97.891C102.856 99.046 101.799 100.251 100.289 100.603Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_34" d="M101.095 95.4302C100.692 95.8822 100.289 96.3843 99.8865 96.8363C99.4335 96.4848 98.9804 96.1835 98.5273 95.8319C99.3328 95.2796 100.189 95.2293 101.095 95.4302Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_35" d="M100.088 96.7859C98.7285 97.1876 97.621 96.4343 97.47 95.0784C97.319 93.6723 98.2251 92.467 99.6849 92.2661C101.094 92.1155 102.353 92.919 103.46 93.7225C102.655 95.0282 101.598 96.3339 100.088 96.7859Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_36" d="M103.46 93.7727C104.115 94.2247 104.467 94.9278 104.518 95.7313C104.568 96.5348 104.266 97.2881 103.762 97.8907" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_37" d="M102.806 89.6045C103.461 90.0063 103.914 90.6591 104.065 91.4627C104.216 92.2662 103.964 93.1199 103.461 93.7728" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_38" d="M103.662 96.7358C103.36 97.1878 103.058 97.6398 102.706 98.0415C102.202 97.7402 101.699 97.4389 101.145 97.238C101.699 96.8864 102.152 96.4847 102.605 96.0327C102.957 96.2838 103.31 96.4847 103.662 96.7358Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_39" d="M105.021 94.5763C104.87 94.1746 104.669 93.8733 104.417 93.5217C104.115 94.0239 103.813 94.4759 103.511 94.9279C103.712 95.1288 103.762 95.4301 103.813 95.7314C104.216 95.3798 104.669 94.9781 105.021 94.5763Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          </g>
          <g id="Group_8" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
            <path id="Vector_40" d="M103.259 92.6177C103.007 93.1199 102.705 93.5719 102.403 94.0238C101.9 93.7225 101.397 93.4212 100.843 93.2706C101.346 92.8688 101.799 92.467 102.202 91.9648C102.605 92.1155 102.957 92.3164 103.259 92.6177Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_41" d="M100.642 91.4626C100.289 91.9649 99.9368 92.4671 99.5844 92.9693C99.1313 92.668 98.6279 92.3666 98.1245 92.1155C98.8293 91.4626 99.7354 91.3622 100.642 91.4626Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_42" d="M99.7354 92.8688C98.4266 93.371 97.2185 92.7683 96.9668 91.4124C96.7151 90.0564 97.4198 88.8009 98.8293 88.4996C100.189 88.1983 101.598 88.9013 102.806 89.5542C102.152 90.9102 101.195 92.3163 99.7354 92.8688Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_43" d="M99.6854 87.5957C99.3834 88.0979 99.0814 88.6001 98.7793 89.1023C98.2759 88.8512 97.7222 88.6503 97.2188 88.3992C97.9235 87.7966 98.7793 87.6459 99.6854 87.5957Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_44" d="M98.9804 89.0519C97.7722 89.6546 96.4634 89.2528 95.96 87.8969C95.4566 86.5409 96.0607 85.4361 97.4702 84.9841C98.8294 84.5824 100.34 85.0343 101.598 85.5867C101.095 86.9929 100.34 88.3991 98.9804 89.0519Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_45" d="M101.699 85.6372C102.403 85.9385 102.957 86.4909 103.159 87.2945C103.36 88.098 103.209 88.9015 102.856 89.6046" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_46" d="M100.138 82.0212C100.893 82.2221 101.497 82.6741 101.799 83.4274C102.101 84.1807 102.051 84.934 101.699 85.6371" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_47" d="M102.504 88.4494C102.303 88.9516 102.101 89.4538 101.799 89.9058C101.246 89.6547 100.742 89.4036 100.138 89.3032C100.591 88.9014 100.994 88.4494 101.346 87.897C101.749 88.0476 102.152 88.2485 102.504 88.4494Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_48" d="M103.411 86.0891C103.159 85.7375 102.907 85.4865 102.605 85.2354C102.404 85.7376 102.202 86.2397 102.001 86.6917C102.253 86.8424 102.404 87.1437 102.504 87.3948C102.806 86.9931 103.108 86.5411 103.411 86.0891Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          </g>
          <g id="Group_9" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
            <path id="Vector_49" d="M101.246 84.6327C101.095 85.1349 100.893 85.5869 100.692 86.0891C100.138 85.8882 99.5343 85.6874 98.9302 85.6371C99.3329 85.1852 99.6853 84.7332 100.038 84.231C100.44 84.2812 100.843 84.4318 101.246 84.6327Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_50" d="M98.326 84.1304C98.0743 84.6326 97.8226 85.1348 97.5709 85.637C97.0171 85.4863 96.4634 85.2855 95.9097 85.1348C96.4634 84.4317 97.3695 84.2308 98.326 84.1304Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_51" d="M97.722 85.5367C96.5138 86.2398 95.1546 85.9887 94.5506 84.683C93.9465 83.3772 94.5506 82.2222 95.9097 81.72C97.1682 81.2178 98.8295 81.6195 100.138 82.0213C99.6349 83.4275 99.0308 84.7834 97.722 85.5367Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_52" d="M96.6144 80.8159C96.4131 81.3181 96.1614 81.8203 95.96 82.3225C95.4062 82.2221 94.8525 82.1217 94.3491 81.971C94.8525 81.2679 95.7587 81.0168 96.6144 80.8159Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_53" d="M96.111 82.2221C95.0538 82.9251 93.6443 82.9251 93.0402 81.7198C92.4361 80.5145 93.0402 79.4599 94.2987 78.8573C95.5069 78.3048 97.0674 78.3551 98.3259 78.7066C97.9735 80.0123 97.2688 81.4185 96.111 82.2221Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_54" d="M98.376 78.7068C99.0807 78.8574 99.6848 79.209 100.088 79.9121C100.44 80.6151 100.39 81.3684 100.138 82.0715" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_55" d="M96.4634 75.593C97.1681 75.6432 97.8226 75.9948 98.2253 76.6476C98.628 77.3005 98.628 78.0036 98.3763 78.7067" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_56" d="M99.5847 81.0669C99.4336 81.5691 99.333 82.0714 99.1316 82.5233C98.5275 82.4229 97.9234 82.2722 97.3193 82.2722C97.7221 81.8203 98.0241 81.3181 98.3261 80.7656C98.7792 80.8661 99.1819 80.9665 99.5847 81.0669Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_57" d="M100.189 78.8072C99.8867 78.5561 99.635 78.3552 99.2826 78.2046C99.1316 78.7068 98.9806 79.1587 98.8799 79.6609C99.1316 79.7614 99.3329 79.9622 99.4839 80.1631C99.6853 79.7112 99.937 79.2592 100.189 78.8072Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          </g>
          <g id="Group_10" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
            <path id="Vector_58" d="M97.8223 77.8529C97.7216 78.3049 97.5706 78.8071 97.3693 79.2591C96.8155 79.1587 96.2114 79.2089 95.6577 79.2591C96.0101 78.8071 96.3625 78.3049 96.6142 77.7524C97.0672 77.7022 97.4699 77.7022 97.8223 77.8529Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_59" d="M95.0031 77.853C94.8017 78.4055 94.6004 78.9077 94.3487 79.4099C93.7949 79.3597 93.2915 79.209 92.7378 79.1588C93.2915 78.4055 94.1473 78.1041 95.0031 77.853Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_60" d="M94.55 79.3093C93.5936 80.0626 92.2344 80.1128 91.5297 79.0582C90.8249 78.0036 91.278 76.8988 92.4358 76.1957C93.4929 75.4926 95.1541 75.4926 96.463 75.6432C96.3623 76.9992 95.6072 78.5058 94.55 79.3093Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_61" d="M92.9897 75.1411C92.8386 75.6433 92.6876 76.1455 92.5869 76.6477C92.0332 76.6477 91.5298 76.698 90.9761 76.698C91.3788 75.9447 92.1842 75.4927 92.9897 75.1411Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_62" d="M92.738 76.5473C91.8822 77.4011 90.5734 77.7024 89.7176 76.7984C88.8618 75.8945 89.1135 74.7394 90.1706 73.9359C91.1774 73.1826 92.8387 72.8311 94.1475 72.8311C94.1475 74.2372 93.6441 75.5932 92.738 76.5473Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_63" d="M94.1475 72.831C94.8522 72.7807 95.6073 73.0318 96.0603 73.6345C96.5134 74.1869 96.614 74.89 96.5134 75.5931" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_64" d="M91.2783 70.5208C91.9831 70.3702 92.8389 70.5208 93.3926 71.023C93.9464 71.475 94.1981 72.1278 94.0974 72.8309" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_65" d="M95.8084 74.8397C95.8084 75.2917 95.7581 75.7436 95.6071 76.1956C95.003 76.1956 94.399 76.1956 93.8452 76.296C94.1473 75.8441 94.4493 75.3419 94.6003 74.8397C95.003 74.7894 95.4057 74.7894 95.8084 74.8397Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_66" d="M95.9595 72.63C95.6071 72.4291 95.2547 72.3287 94.9023 72.2283C94.9023 72.6803 94.9023 73.1824 94.9023 73.6344C95.154 73.6846 95.3554 73.8353 95.5568 74.0362C95.6574 73.5842 95.8085 73.082 95.9595 72.63Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          </g>
          <path id="Vector_67" d="M93.3424 72.2283C93.3424 72.6802 93.3927 73.1322 93.3424 73.5842C92.7887 73.6846 92.1846 73.7851 91.6309 73.9357C91.8826 73.4335 92.0336 72.9313 92.0839 72.3789C92.4866 72.2785 92.9397 72.2283 93.3424 72.2283Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_68" d="M90.473 72.9817C90.473 73.4839 90.473 73.9861 90.3723 74.4381C89.8185 74.5385 89.2647 74.6389 88.6606 74.7896C89.0634 73.8856 89.6675 73.3834 90.473 72.9817Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_69" d="M90.5234 74.2872C89.7683 75.1912 88.4595 75.7436 87.4023 75.0405C86.3452 74.3374 86.3955 73.2326 87.352 72.2282C88.2581 71.3243 89.9697 70.6714 91.2785 70.5208C91.5302 71.7762 91.3288 73.2828 90.5234 74.2872Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_70" d="M87.5029 71.2239C87.6036 71.7261 87.6539 72.1781 87.7043 72.6802C87.1002 72.8309 86.5464 73.082 85.9927 73.2828C86.0934 72.3789 86.7981 71.7261 87.5029 71.2239Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_71" d="M87.7544 72.5297C87.0999 73.5843 85.7911 74.2874 84.5829 73.7852C83.3748 73.283 83.2237 72.0777 84.1299 70.9729C84.9856 69.9183 86.4958 69.0645 87.9054 68.7632C88.3081 70.0187 88.5095 71.3244 87.7544 72.5297Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_72" d="M87.9053 68.7631C88.6604 68.5622 89.4658 68.512 90.1706 68.8635C90.8753 69.2151 91.1774 69.8177 91.278 70.5209" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_73" d="M84.4321 67.2063C85.1369 66.9049 85.9423 66.8547 86.647 67.1561C87.3518 67.4574 87.7545 68.06 87.9055 68.763" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_74" d="M90.3217 70.0186C90.4224 70.4705 90.5734 70.8723 90.5734 71.3243C89.9693 71.5252 89.3653 71.6758 88.8115 71.9269C88.9625 71.4247 89.0632 70.8723 89.0129 70.3199C89.4659 70.2195 89.919 70.119 90.3217 70.0186Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_75" d="M89.7179 67.9597C89.3152 67.8592 88.9125 67.8592 88.5098 67.9095C88.6608 68.3614 88.7615 68.8134 88.9125 69.2653C89.2145 69.2151 89.4662 69.3156 89.7682 69.416C89.7179 68.9138 89.7179 68.4116 89.7179 67.9597Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_76" d="M86.8986 68.311C87.0497 68.7128 87.2007 69.1648 87.3014 69.5666C86.6973 69.8177 86.1435 70.0688 85.6401 70.4203C85.7408 69.8679 85.7408 69.3657 85.6905 68.8132C86.0932 68.6124 86.4959 68.4115 86.8986 68.311Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_77" d="M84.2308 69.8679C84.3315 70.3701 84.4322 70.8221 84.5328 71.3243C83.9791 71.5754 83.4254 71.8265 82.8213 72.0776C82.8716 71.1737 83.5764 70.4706 84.2308 69.8679Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_78" d="M84.5828 71.1234C84.0291 72.3286 82.7706 73.1322 81.5121 72.63C80.2536 72.1278 80.1529 70.7216 80.908 69.5666C81.6128 68.4617 83.1733 67.6582 84.4318 67.156C84.7842 68.5119 85.1869 69.8176 84.5828 71.1234Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_79" d="M80.9587 68.3613C81.0594 68.9137 81.1097 69.4661 81.2607 70.0186C80.7573 70.2194 80.2539 70.4705 79.7505 70.6714C79.8008 69.7675 80.3546 69.0142 80.9587 68.3613Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_80" d="M81.3613 69.8678C80.9586 71.0731 79.7505 71.8766 78.5423 71.1735C77.3342 70.4704 77.2838 69.014 78.0893 67.859C78.8443 66.7541 80.2035 66.051 81.4117 65.5488C81.6634 66.955 81.8144 68.5118 81.3613 69.8678Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_81" d="M81.4116 65.5489C82.0157 65.2978 82.7205 65.2476 83.3245 65.5489C83.9286 65.8502 84.281 66.503 84.432 67.2061" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_82" d="M78.4917 63.8415C79.0958 63.5904 79.7502 63.5402 80.304 63.8917C80.9081 64.2433 81.2604 64.8961 81.3611 65.5489" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_83" d="M83.5764 66.7542C83.7274 67.2061 83.8281 67.7084 83.9287 68.1604C83.375 68.4617 82.8213 68.763 82.2676 69.1648C82.3683 68.5621 82.4186 67.9595 82.3682 67.3066C82.7709 67.1057 83.1233 66.9048 83.5764 66.7542Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_84" d="M82.8716 64.5947C82.5192 64.5445 82.1668 64.5445 81.8647 64.645C82.0158 65.097 82.1165 65.5992 82.2675 66.0511C82.5192 66.0009 82.7709 66.0511 82.9723 66.1516C82.9219 65.6494 82.8716 65.1472 82.8716 64.5947Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        </g>
        <g id="Group_11" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
          <g id="Group_12" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
            <path id="Vector_85" d="M124.15 69.8681C124.553 69.6672 124.956 69.4663 125.409 69.3157C125.761 69.8179 126.114 70.2698 126.567 70.7218C126.013 70.7218 125.459 70.772 124.905 70.9227C124.603 70.5712 124.352 70.2698 124.15 69.8681Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_86" d="M126.063 72.1279C126.566 71.9773 127.07 71.7764 127.573 71.6257C127.875 72.1279 128.177 72.6301 128.53 73.0821C127.573 73.1825 126.768 72.6803 126.063 72.1279Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_87" d="M127.372 71.5756C128.63 71.8769 129.637 72.9818 129.184 74.3377C128.731 75.6937 127.473 75.9448 126.214 75.2919C125.006 74.6893 124.1 73.3333 123.395 72.1783C124.654 71.6259 126.013 71.2241 127.372 71.5756Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_88" d="M125.107 75.3421C125.61 75.1914 126.114 74.9905 126.617 74.8398C126.919 75.3421 127.221 75.8443 127.574 76.2963C126.617 76.3967 125.812 75.8945 125.107 75.3421Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_89" d="M126.415 74.7897C127.674 75.091 128.681 76.1959 128.228 77.5016C127.774 78.8073 126.415 79.1589 125.207 78.4558C124.049 77.8029 123.143 76.4972 122.438 75.3421C123.697 74.8399 125.006 74.4381 126.415 74.7897Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_90" d="M122.388 75.3923C122.036 74.8399 121.835 74.1368 122.036 73.484C122.237 72.8311 122.741 72.4293 123.395 72.1782" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_91" d="M121.08 78.8575C120.727 78.2047 120.727 77.4011 121.029 76.7483C121.281 76.0954 121.784 75.6434 122.439 75.3923" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_92" d="M123.194 73.0823C123.597 72.8814 123.999 72.6805 124.452 72.5298C124.805 73.032 125.157 73.484 125.61 73.936C125.056 73.936 124.503 73.9862 123.949 74.1369C123.647 73.7854 123.395 73.4338 123.194 73.0823Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_93" d="M121.281 74.0364C121.281 74.388 121.381 74.7396 121.482 75.0409C121.885 74.84 122.338 74.6893 122.741 74.4884C122.64 74.2373 122.64 73.9862 122.69 73.7351C122.237 73.8356 121.734 73.936 121.281 74.0364Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          </g>
          <g id="Group_13" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
            <path id="Vector_94" d="M122.187 76.246C122.59 76.0452 122.992 75.8443 123.445 75.6936C123.798 76.1958 124.15 76.6478 124.603 77.0999C124.049 77.0999 123.496 77.1501 122.942 77.3007C122.64 76.9492 122.388 76.5976 122.187 76.246Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_95" d="M124.1 78.5061C124.603 78.3554 125.106 78.1546 125.61 78.0039C125.912 78.5061 126.214 79.0083 126.566 79.4603C125.61 79.5607 124.804 79.0585 124.1 78.5061Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_96" d="M125.408 77.9538C126.667 78.2551 127.674 79.36 127.221 80.7159C126.768 82.0719 125.509 82.323 124.251 81.6701C123.043 81.0675 122.136 79.7115 121.432 78.5564C122.69 78.004 124.049 77.6023 125.408 77.9538Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_97" d="M123.093 81.7202C123.597 81.5696 124.1 81.3687 124.603 81.218C124.906 81.7202 125.208 82.2224 125.56 82.6744C124.603 82.7749 123.848 82.2727 123.093 81.7202Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_98" d="M124.452 81.1174C125.711 81.4187 126.718 82.5236 126.265 83.8293C125.812 85.135 124.452 85.4865 123.244 84.7835C122.086 84.1306 121.18 82.8249 120.476 81.6698C121.684 81.2179 123.043 80.8161 124.452 81.1174Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_99" d="M120.425 81.7202C120.073 81.1678 119.871 80.4647 120.073 79.8118C120.274 79.159 120.777 78.7572 121.432 78.5061" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_100" d="M119.066 85.1854C118.713 84.5325 118.713 83.729 119.016 83.0761C119.267 82.4233 119.771 81.9713 120.425 81.7202" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_101" d="M121.18 79.4601C121.583 79.2593 121.986 79.0584 122.439 78.9077C122.791 79.4099 123.143 79.8619 123.596 80.3139C123.043 80.3139 122.489 80.3641 121.935 80.5147C121.633 80.1632 121.432 79.8117 121.18 79.4601Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_102" d="M119.267 80.3641C119.267 80.7156 119.368 81.0672 119.468 81.3685C119.871 81.1676 120.324 81.017 120.727 80.8161C120.626 80.565 120.626 80.3139 120.677 80.0627C120.224 80.2134 119.77 80.2636 119.267 80.3641Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          </g>
          <g id="Group_14" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
            <path id="Vector_103" d="M120.173 82.6742C120.576 82.4734 121.029 82.2725 121.431 82.1218C121.784 82.624 122.136 83.076 122.589 83.528C122.035 83.528 121.431 83.5782 120.878 83.7289C120.676 83.3271 120.425 83.0258 120.173 82.6742Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_104" d="M122.036 84.9843C122.589 84.7834 123.093 84.6328 123.646 84.4319C123.898 84.9341 124.15 85.4363 124.402 85.9385C123.445 85.9887 122.69 85.5368 122.036 84.9843Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_105" d="M123.395 84.3314C124.754 84.683 125.408 85.838 124.855 87.1437C124.301 88.4495 122.841 89.0019 121.582 88.349C120.374 87.7464 119.619 86.3904 119.065 85.1852C120.425 84.5825 121.935 83.9799 123.395 84.3314Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_106" d="M120.375 88.6003C120.928 88.3492 121.532 88.1483 122.086 87.8972C122.338 88.3994 122.59 88.9016 122.841 89.4038C121.834 89.5545 121.079 89.1527 120.375 88.6003Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_107" d="M121.834 87.7966C123.193 88.0477 123.898 89.2028 123.344 90.5086C122.791 91.8143 121.482 92.5174 120.123 91.9649C118.814 91.4627 118.059 90.157 117.455 88.9517C118.763 88.1984 120.324 87.5455 121.834 87.7966Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_108" d="M117.455 89.0021C117.102 88.299 117.102 87.5457 117.404 86.7924C117.706 86.0391 118.31 85.4867 119.065 85.2356" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_109" d="M116.095 92.9693C115.693 92.3165 115.592 91.513 115.844 90.7597C116.095 90.0064 116.7 89.3535 117.455 89.002" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_110" d="M118.663 86.2397C119.116 85.9886 119.619 85.7375 120.122 85.5366C120.424 86.0388 120.727 86.5411 121.129 86.9931C120.475 87.0433 119.871 87.194 119.267 87.3948C119.065 86.9931 118.864 86.6415 118.663 86.2397Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_111" d="M116.448 87.5457C116.398 87.9474 116.398 88.3492 116.498 88.751C117.002 88.4497 117.455 88.1985 117.958 87.8972C117.908 87.5959 117.958 87.3448 118.059 87.0435C117.505 87.1941 116.952 87.3448 116.448 87.5457Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          </g>
          <g id="Group_15" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
            <path id="Vector_112" d="M117.103 90.1068C117.556 89.8055 118.009 89.5043 118.512 89.2532C118.814 89.7553 119.166 90.2575 119.569 90.6593C118.965 90.7597 118.361 90.9606 117.757 91.1614C117.505 90.8601 117.254 90.5086 117.103 90.1068Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_113" d="M118.915 92.3166C119.468 92.0153 120.022 91.7642 120.576 91.4629C120.827 91.9651 121.18 92.4171 121.482 92.9193C120.525 93.1704 119.67 92.8188 118.915 92.3166Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_114" d="M120.375 91.4629C121.734 91.6136 122.539 92.6682 122.187 94.0241C121.784 95.38 120.626 96.1333 119.166 95.7315C117.807 95.38 116.901 94.1245 116.146 92.9695C117.304 92.1157 118.814 91.3122 120.375 91.4629Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_115" d="M117.958 96.1834C118.462 95.8821 118.965 95.5306 119.469 95.2292C119.821 95.6812 120.173 96.1332 120.576 96.5349C119.67 96.8362 118.814 96.5851 117.958 96.1834Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_116" d="M119.267 95.179C120.626 95.179 121.583 96.1833 121.432 97.5895C121.231 98.9957 120.224 99.749 118.764 99.4476C117.354 99.1965 116.247 98.1419 115.341 97.0371C116.448 96.0829 117.757 95.179 119.267 95.179Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_117" d="M115.29 97.0373C114.787 96.4346 114.586 95.6813 114.737 94.8778C114.888 94.0743 115.391 93.4215 116.096 92.9695" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_118" d="M115.039 100.904C114.485 100.352 114.133 99.6989 114.183 98.8954C114.233 98.0919 114.686 97.439 115.29 96.9871" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_119" d="M115.895 94.1243C116.297 93.7728 116.75 93.4213 117.203 93.1702C117.556 93.6222 117.958 94.0741 118.411 94.4759C117.807 94.6266 117.254 94.8275 116.7 95.179C116.348 94.8275 116.096 94.4759 115.895 94.1243Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_120" d="M113.981 95.7817C114.032 96.1835 114.132 96.535 114.334 96.9367C114.737 96.5852 115.139 96.2337 115.592 95.9324C115.441 95.6813 115.441 95.3799 115.492 95.0786C114.938 95.3297 114.485 95.5808 113.981 95.7817Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          </g>
          <g id="Group_16" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
            <path id="Vector_121" d="M115.24 98.1421C115.592 97.7906 115.995 97.439 116.398 97.1377C116.8 97.5395 117.253 97.9915 117.757 98.2928C117.203 98.4937 116.649 98.7448 116.146 99.0461C115.793 98.795 115.491 98.4937 115.24 98.1421Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_122" d="M117.606 99.8999C118.059 99.5484 118.512 99.247 118.965 98.8955C119.368 99.2973 119.821 99.699 120.224 100.101C119.368 100.503 118.462 100.251 117.606 99.8999Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_123" d="M118.764 98.8954C120.123 98.795 121.23 99.6488 121.18 101.105C121.13 102.511 120.073 103.265 118.613 103.114C117.254 102.963 115.995 101.858 115.039 100.904C116.096 99.8998 117.254 98.9959 118.764 98.8954Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_124" d="M117.606 103.666C118.009 103.315 118.462 102.963 118.864 102.612C119.267 102.963 119.72 103.315 120.173 103.666C119.368 104.018 118.462 103.867 117.606 103.666Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_125" d="M118.663 102.612C119.922 102.461 121.18 103.114 121.18 104.47C121.18 105.826 120.123 106.479 118.713 106.429C117.405 106.378 116.045 105.625 115.039 104.721C116.045 103.666 117.304 102.763 118.663 102.612Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_126" d="M115.039 104.671C114.485 104.219 114.082 103.616 114.082 102.813C114.082 102.009 114.434 101.356 115.039 100.904" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_127" d="M115.29 108.337C114.686 107.935 114.283 107.332 114.233 106.629C114.183 105.876 114.485 105.223 115.038 104.721" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_128" d="M115.089 102.009C115.441 101.607 115.794 101.256 116.196 100.904C116.649 101.306 117.153 101.658 117.656 101.959C117.102 102.21 116.549 102.461 116.045 102.813C115.693 102.612 115.391 102.31 115.089 102.009Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_129" d="M113.478 103.767C113.629 104.118 113.78 104.42 114.032 104.721C114.384 104.369 114.737 103.968 115.089 103.616C114.888 103.415 114.837 103.164 114.787 102.913C114.384 103.214 113.931 103.466 113.478 103.767Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          </g>
          <g id="Group_17" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
            <path id="Vector_130" d="M115.139 105.725C115.441 105.374 115.794 105.022 116.146 104.721C116.599 105.072 117.153 105.324 117.656 105.524C117.102 105.776 116.599 106.077 116.096 106.428C115.794 106.177 115.441 105.976 115.139 105.725Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_131" d="M117.656 106.981C118.059 106.579 118.512 106.228 118.965 105.876C119.418 106.177 119.821 106.529 120.274 106.83C119.469 107.282 118.512 107.182 117.656 106.981Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_132" d="M118.714 105.926C119.922 105.725 121.18 106.278 121.281 107.533C121.382 108.789 120.476 109.542 119.167 109.693C117.908 109.793 116.398 109.04 115.341 108.337C116.046 107.132 117.405 106.127 118.714 105.926Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_133" d="M118.26 110.295C118.613 109.894 118.965 109.542 119.317 109.14C119.821 109.341 120.274 109.592 120.777 109.843C120.022 110.346 119.116 110.396 118.26 110.295Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_134" d="M119.066 109.191C120.223 108.789 121.532 109.14 121.885 110.346C122.237 111.551 121.482 112.405 120.173 112.656C118.965 112.907 117.304 112.455 116.146 111.802C116.8 110.597 117.807 109.643 119.066 109.191Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_135" d="M116.146 111.852C115.492 111.551 114.938 110.999 114.787 110.245C114.636 109.542 114.888 108.889 115.29 108.287" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_136" d="M117.606 115.167C116.901 114.966 116.247 114.464 115.945 113.761C115.643 113.058 115.743 112.405 116.146 111.802" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_137" d="M115.542 109.291C115.794 108.889 116.045 108.487 116.347 108.186C116.901 108.437 117.405 108.738 117.958 108.939C117.455 109.19 117.002 109.492 116.599 109.894C116.247 109.693 115.894 109.492 115.542 109.291Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
            <path id="Vector_138" d="M114.435 111.199C114.636 111.5 114.888 111.802 115.19 112.053C115.391 111.651 115.643 111.199 115.844 110.797C115.643 110.647 115.542 110.396 115.441 110.145C115.139 110.496 114.737 110.848 114.435 111.199Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          </g>
          <path id="Vector_139" d="M116.549 112.756C116.75 112.354 116.952 111.953 117.203 111.551C117.757 111.752 118.311 111.902 118.864 112.003C118.411 112.354 118.059 112.706 117.707 113.158C117.304 113.108 116.901 112.957 116.549 112.756Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_140" d="M119.418 113.409C119.67 113.007 119.871 112.555 120.173 112.154C120.727 112.354 121.281 112.455 121.834 112.606C121.13 113.208 120.374 113.409 119.418 113.409Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_141" d="M120.022 112.204C121.079 111.752 122.539 111.852 123.143 112.957C123.747 114.062 123.194 115.016 121.885 115.468C120.677 115.87 118.864 115.669 117.606 115.167C117.958 113.961 118.814 112.706 120.022 112.204Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_142" d="M121.281 116.322C121.432 115.87 121.633 115.368 121.784 114.916C122.388 115.016 122.992 115.117 123.596 115.167C123.093 115.92 122.137 116.171 121.281 116.322Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_143" d="M121.633 115.016C122.69 114.363 124.15 114.313 125.006 115.368C125.862 116.422 125.459 117.477 124.15 118.079C122.892 118.632 121.18 118.682 119.771 118.28C120.022 117.025 120.475 115.769 121.633 115.016Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_144" d="M119.771 118.331C119.016 118.18 118.261 117.828 117.807 117.176C117.354 116.523 117.354 115.87 117.606 115.167" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_145" d="M122.137 121.294C121.381 121.243 120.626 120.942 120.173 120.339C119.72 119.737 119.569 119.034 119.771 118.331" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_146" d="M118.21 116.071C118.311 115.619 118.411 115.167 118.562 114.765C119.166 114.865 119.77 115.016 120.425 115.016C120.072 115.418 119.72 115.82 119.519 116.322C119.066 116.271 118.613 116.171 118.21 116.071Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_147" d="M117.807 118.23C118.16 118.481 118.462 118.682 118.864 118.833C118.965 118.381 119.066 117.879 119.116 117.427C118.864 117.326 118.613 117.125 118.411 116.924C118.26 117.376 118.059 117.778 117.807 118.23Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_148" d="M120.475 119.134C120.525 118.682 120.576 118.23 120.727 117.828C121.331 117.879 121.985 117.929 122.589 117.828C122.287 118.28 122.036 118.732 121.784 119.235C121.331 119.285 120.928 119.285 120.475 119.134Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_149" d="M123.596 119.034C123.747 118.582 123.898 118.08 123.999 117.628C124.603 117.678 125.207 117.678 125.861 117.728C125.358 118.481 124.452 118.783 123.596 119.034Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_150" d="M123.797 117.728C124.804 116.924 126.365 116.774 127.221 117.778C128.127 118.833 127.573 120.088 126.365 120.791C125.207 121.444 123.445 121.444 122.136 121.294C122.438 119.938 122.69 118.632 123.797 117.728Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_151" d="M125.761 121.846C125.962 121.294 126.113 120.791 126.264 120.239C126.818 120.289 127.372 120.339 127.925 120.339C127.472 121.143 126.667 121.545 125.761 121.846Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_152" d="M126.113 120.339C127.019 119.435 128.479 119.285 129.234 120.44C129.989 121.595 129.335 122.951 128.127 123.604C126.969 124.257 125.408 124.257 124.1 124.106C124.502 122.8 125.106 121.344 126.113 120.339Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_153" d="M124.1 124.156C123.445 124.106 122.791 123.855 122.388 123.252C121.985 122.649 121.985 121.946 122.136 121.293" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_154" d="M125.912 127.019C125.258 126.969 124.654 126.717 124.301 126.115C123.949 125.512 123.949 124.809 124.1 124.156" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_155" d="M122.741 122.097C122.841 121.595 122.992 121.143 123.093 120.641C123.748 120.641 124.352 120.641 125.006 120.54C124.654 121.043 124.352 121.545 124.05 122.097C123.597 122.147 123.194 122.147 122.741 122.097Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_156" d="M122.388 124.307C122.69 124.508 122.992 124.658 123.294 124.759C123.395 124.257 123.496 123.805 123.596 123.302C123.345 123.252 123.143 123.102 122.992 122.901C122.791 123.353 122.59 123.855 122.388 124.307Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        </g>
        <path id="Vector_157" d="M132.456 113.811L80.6062 83.5781L80.3042 98.2927L133.06 121.746L132.456 113.811Z" fill="#FFBB24" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_158" d="M86.8481 58.1165L106.732 70.4204C109.501 72.1279 113.075 71.5755 115.189 69.1649L124.251 58.6689" stroke="#B2C5D3" stroke-width="8" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <g id="Group_18" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
          <g id="Group_19" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
            <path id="Vector_159" d="M142.625 90.1069C141.669 91.8646 139.454 92.4672 137.742 91.513L134.621 89.8055C130.997 87.7967 128.681 83.98 128.631 79.8619L128.58 76.3967C128.53 74.3879 130.141 72.7809 132.104 72.7306C134.118 72.6804 135.729 74.2875 135.779 76.2461L135.829 79.7113C135.88 81.2681 136.735 82.7245 138.094 83.528L141.216 85.2355C142.977 86.1897 143.632 88.3994 142.625 90.1069Z" fill="#FF7171" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          </g>
        </g>
        <path id="Vector_160" opacity="0.37" d="M132.607 83.8291C130.896 81.4186 131.6 72.8309 131.6 72.7305C129.839 72.9816 128.53 74.5384 128.58 76.3463L128.63 79.8115C128.681 83.9296 130.996 87.7463 134.621 89.7551L137.742 91.4626C139.504 92.4168 141.668 91.8142 142.625 90.0564C142.625 90.1067 134.319 86.2397 132.607 83.8291Z" fill="#F26161" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
      </g>
      <path id="Vector_161" d="M144.538 84.2814C147.055 82.7245 152.089 79.1589 155.713 72.5298C160.143 64.3439 159.64 56.61 159.287 53.7474C136.634 41.0919 114.686 28.6875 114.686 28.6875C115.189 30.395 117.354 38.1791 113.176 46.5157C108.897 55.1536 100.943 58.1166 99.3828 58.669C106.179 62.988 144.538 84.2814 144.538 84.2814Z" fill="#FFBB24" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
      <g id="Group_20" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
        <path id="Vector_162" d="M117.404 32.5545L116.851 32.9061L119.317 37.0744L120.022 36.6726C120.727 36.2708 120.727 35.0153 120.022 33.8602C119.368 32.7052 118.26 32.1025 117.555 32.5043C117.455 32.4541 117.455 32.5043 117.404 32.5545Z" fill="#D4E0E8" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_163" d="M119.293 37.0574C119.988 36.6499 120.003 35.3891 119.327 34.2412C118.651 33.0933 117.54 32.4931 116.845 32.9006C116.149 33.3081 116.134 34.569 116.81 35.7168C117.486 36.8647 118.598 37.4649 119.293 37.0574Z" fill="#E9EFF4" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_164" d="M119.116 36.7227L117.052 33.2073" stroke="#D4E0E8" stroke-width="0.7713" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
      </g>
      <g id="Group_21" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
        <path id="Vector_165" d="M107.79 56.4593L107.236 56.8108L109.703 60.9791L110.407 60.5774C111.112 60.1756 111.112 58.9201 110.407 57.765C109.753 56.61 108.595 56.0073 107.941 56.4091C107.84 56.4091 107.79 56.4593 107.79 56.4593Z" fill="#D4E0E8" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_166" d="M109.66 60.962C110.355 60.5545 110.37 59.2936 109.694 58.1458C109.018 56.9979 107.906 56.3977 107.211 56.8052C106.516 57.2126 106.501 58.4735 107.177 59.6214C107.853 60.7692 108.964 61.3694 109.66 60.962Z" fill="#E9EFF4" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_167" d="M109.501 60.6777L107.437 57.1624" stroke="#D4E0E8" stroke-width="0.7713" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
      </g>
      <g id="Group_22" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
        <path id="Vector_168" d="M155.411 54.8021L154.857 55.1536L157.324 59.3219L158.028 58.9202C158.733 58.5184 158.733 57.2629 158.028 56.1078C157.374 54.9527 156.216 54.3501 155.562 54.7519C155.461 54.7016 155.411 54.7518 155.411 54.8021Z" fill="#D4E0E8" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_169" d="M157.277 59.2581C157.972 58.8506 157.988 57.5897 157.312 56.4419C156.636 55.294 155.524 54.6938 154.829 55.1013C154.134 55.5088 154.118 56.7696 154.794 57.9175C155.47 59.0654 156.582 59.6656 157.277 59.2581Z" fill="#E9EFF4" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_170" d="M157.122 58.9705L155.059 55.4551" stroke="#D4E0E8" stroke-width="0.7713" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
      </g>
      <g id="Group_23" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
        <path id="Vector_171" d="M143.028 76.1456L142.474 76.4971L144.941 80.6653L145.646 80.2636C146.35 79.8618 146.35 78.6063 145.646 77.4513C144.991 76.2962 143.884 75.6936 143.179 76.0954C143.129 76.0954 143.078 76.0953 143.028 76.1456Z" fill="#D4E0E8" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_172" d="M144.911 80.6682C145.606 80.2608 145.622 78.9999 144.946 77.852C144.27 76.7042 143.158 76.104 142.463 76.5115C141.768 76.9189 141.752 78.1798 142.428 79.3276C143.104 80.4755 144.216 81.0757 144.911 80.6682Z" fill="#E9EFF4" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_173" d="M144.79 80.3638L142.726 76.8484" stroke="#D4E0E8" stroke-width="0.7713" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
      </g>
      <g id="Group_24" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
        <g id="Group_25" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
          <path id="Vector_174" d="M122.187 76.246C122.59 76.0451 122.992 75.8443 123.445 75.6936C123.798 76.1958 124.15 76.6478 124.603 77.0997C124.049 77.0997 123.496 77.1499 122.942 77.3006C122.64 76.9491 122.388 76.5975 122.187 76.246Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_175" d="M124.1 78.5059C124.603 78.3552 125.106 78.1543 125.61 78.0037C125.912 78.5059 126.214 79.0081 126.566 79.4601C125.61 79.5605 124.804 79.0583 124.1 78.5059Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_176" d="M125.408 77.9536C126.667 78.2549 127.674 79.3597 127.221 80.7157C126.768 82.0716 125.509 82.3227 124.251 81.6699C123.043 81.0672 122.136 79.7113 121.432 78.5562C122.69 78.0038 124.049 77.602 125.408 77.9536Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_177" d="M123.093 81.72C123.597 81.5693 124.1 81.3684 124.603 81.2178C124.906 81.72 125.208 82.2222 125.56 82.6742C124.603 82.7746 123.848 82.2724 123.093 81.72Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_178" d="M124.452 81.1174C125.711 81.4188 126.718 82.5236 126.265 83.8293C125.812 85.1351 124.452 85.4866 123.244 84.7835C122.086 84.1307 121.18 82.8249 120.476 81.6698C121.684 81.2179 123.043 80.8161 124.452 81.1174Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_179" d="M120.425 81.72C120.073 81.1675 119.871 80.4644 120.073 79.8116C120.274 79.1587 120.777 78.757 121.432 78.5059" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_180" d="M119.066 85.1852C118.713 84.5323 118.713 83.7288 119.016 83.0759C119.267 82.423 119.771 81.9711 120.425 81.72" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_181" d="M121.18 79.4602C121.583 79.2593 121.986 79.0584 122.439 78.9077C122.791 79.4099 123.143 79.8619 123.596 80.3139C123.043 80.3139 122.489 80.3641 121.935 80.5148C121.633 80.1633 121.432 79.8117 121.18 79.4602Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_182" d="M119.267 80.3641C119.267 80.7156 119.368 81.0671 119.468 81.3685C119.871 81.1676 120.324 81.0169 120.727 80.816C120.626 80.5649 120.626 80.3138 120.677 80.0627C120.224 80.2134 119.77 80.2636 119.267 80.3641Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        </g>
        <g id="Group_26" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
          <path id="Vector_183" d="M120.173 82.6742C120.576 82.4734 121.029 82.2725 121.431 82.1218C121.784 82.624 122.136 83.076 122.589 83.528C122.035 83.528 121.431 83.5782 120.878 83.7289C120.676 83.3271 120.425 83.0258 120.173 82.6742Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_184" d="M122.036 84.9845C122.589 84.7837 123.093 84.633 123.646 84.4321C123.898 84.9343 124.15 85.4365 124.402 85.9387C123.445 85.9889 122.69 85.5369 122.036 84.9845Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_185" d="M123.395 84.3314C124.754 84.683 125.408 85.8381 124.855 87.1438C124.301 88.4496 122.841 89.002 121.582 88.3491C120.374 87.7465 119.619 86.3905 119.065 85.1852C120.425 84.5825 121.935 83.9799 123.395 84.3314Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_186" d="M120.375 88.6003C120.928 88.3492 121.532 88.1483 122.086 87.8972C122.338 88.3994 122.59 88.9016 122.841 89.4038C121.834 89.5545 121.079 89.1527 120.375 88.6003Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_187" d="M121.834 87.7966C123.193 88.0477 123.898 89.2028 123.344 90.5086C122.791 91.8143 121.482 92.5174 120.123 91.9649C118.814 91.4627 118.059 90.157 117.455 88.9517C118.763 88.1984 120.324 87.5455 121.834 87.7966Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_188" d="M117.455 89.0022C117.102 88.2991 117.102 87.5457 117.404 86.7924C117.706 86.0391 118.31 85.4867 119.065 85.2356" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_189" d="M116.095 92.9693C115.693 92.3165 115.592 91.513 115.844 90.7597C116.095 90.0064 116.7 89.3535 117.455 89.002" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_190" d="M118.663 86.24C119.116 85.9889 119.619 85.7378 120.122 85.5369C120.424 86.0391 120.727 86.5413 121.129 86.9933C120.475 87.0435 119.871 87.1942 119.267 87.3951C119.065 86.9933 118.864 86.6418 118.663 86.24Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_191" d="M116.448 87.5457C116.398 87.9474 116.398 88.3492 116.498 88.751C117.002 88.4497 117.455 88.1985 117.958 87.8972C117.908 87.5959 117.958 87.3448 118.059 87.0435C117.505 87.1941 116.952 87.3448 116.448 87.5457Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        </g>
        <g id="Group_27" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
          <path id="Vector_192" d="M117.103 90.1067C117.556 89.8053 118.009 89.504 118.512 89.2529C118.814 89.7551 119.166 90.2573 119.569 90.6591C118.965 90.7595 118.361 90.9604 117.757 91.1613C117.505 90.86 117.254 90.5084 117.103 90.1067Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_193" d="M118.915 92.3162C119.468 92.0148 120.022 91.7637 120.576 91.4624C120.827 91.9646 121.18 92.4166 121.482 92.9188C120.525 93.1699 119.67 92.8184 118.915 92.3162Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_194" d="M120.375 91.4626C121.734 91.6133 122.539 92.668 122.187 94.0239C121.784 95.3798 120.626 96.1331 119.166 95.7314C117.807 95.3798 116.901 94.1243 116.146 92.9693C117.304 92.1155 118.814 91.312 120.375 91.4626Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_195" d="M117.958 96.1832C118.462 95.8819 118.965 95.5303 119.469 95.229C119.821 95.681 120.173 96.133 120.576 96.5348C119.67 96.8362 118.814 96.585 117.958 96.1832Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_196" d="M119.267 95.179C120.626 95.179 121.583 96.1833 121.432 97.5895C121.231 98.9957 120.224 99.749 118.764 99.4476C117.354 99.1965 116.247 98.1419 115.341 97.0371C116.448 96.0829 117.757 95.179 119.267 95.179Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_197" d="M115.29 97.0372C114.787 96.4345 114.586 95.6812 114.737 94.8776C114.888 94.0741 115.391 93.4212 116.096 92.9692" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_198" d="M115.039 100.904C114.485 100.352 114.133 99.6987 114.183 98.8951C114.233 98.0916 114.686 97.4388 115.29 96.9868" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_199" d="M115.895 94.1243C116.297 93.7728 116.75 93.4213 117.203 93.1702C117.556 93.6222 117.958 94.0741 118.411 94.4759C117.807 94.6266 117.254 94.8275 116.7 95.179C116.348 94.8275 116.096 94.4759 115.895 94.1243Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_200" d="M113.981 95.7815C114.032 96.1833 114.132 96.5348 114.334 96.9366C114.737 96.585 115.139 96.2335 115.592 95.9321C115.441 95.681 115.441 95.3797 115.492 95.0784C114.938 95.3295 114.485 95.5806 113.981 95.7815Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        </g>
        <g id="Group_28" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
          <path id="Vector_201" d="M115.24 98.1421C115.592 97.7906 115.995 97.439 116.398 97.1377C116.8 97.5395 117.253 97.9915 117.757 98.2928C117.203 98.4937 116.649 98.7448 116.146 99.0461C115.793 98.795 115.491 98.4937 115.24 98.1421Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_202" d="M117.606 99.8997C118.059 99.5482 118.512 99.2468 118.965 98.8953C119.368 99.297 119.821 99.6988 120.224 100.101C119.368 100.502 118.462 100.251 117.606 99.8997Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_203" d="M118.764 98.8954C120.123 98.795 121.23 99.6488 121.18 101.105C121.13 102.511 120.073 103.265 118.613 103.114C117.254 102.963 115.995 101.858 115.039 100.904C116.096 99.8998 117.254 98.9959 118.764 98.8954Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_204" d="M117.606 103.666C118.009 103.315 118.462 102.963 118.864 102.612C119.267 102.963 119.72 103.315 120.173 103.666C119.368 104.018 118.462 103.867 117.606 103.666Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_205" d="M118.663 102.612C119.922 102.461 121.18 103.114 121.18 104.47C121.18 105.826 120.123 106.479 118.713 106.429C117.405 106.378 116.045 105.625 115.039 104.721C116.045 103.666 117.304 102.763 118.663 102.612Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_206" d="M115.039 104.671C114.485 104.219 114.082 103.616 114.082 102.813C114.082 102.009 114.434 101.356 115.039 100.904" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_207" d="M115.29 108.337C114.686 107.935 114.283 107.332 114.233 106.629C114.183 105.876 114.485 105.223 115.038 104.721" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_208" d="M115.089 102.009C115.441 101.607 115.794 101.256 116.196 100.904C116.649 101.306 117.153 101.658 117.656 101.959C117.102 102.21 116.549 102.461 116.045 102.813C115.693 102.612 115.391 102.31 115.089 102.009Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_209" d="M113.478 103.767C113.629 104.118 113.78 104.42 114.032 104.721C114.384 104.37 114.737 103.968 115.089 103.616C114.888 103.415 114.837 103.164 114.787 102.913C114.384 103.214 113.931 103.466 113.478 103.767Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        </g>
        <g id="Group_29" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
          <path id="Vector_210" d="M115.139 105.725C115.441 105.374 115.794 105.022 116.146 104.721C116.599 105.072 117.153 105.324 117.656 105.524C117.102 105.776 116.599 106.077 116.096 106.428C115.794 106.177 115.441 105.976 115.139 105.725Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_211" d="M117.656 106.981C118.059 106.579 118.512 106.228 118.965 105.876C119.418 106.177 119.821 106.529 120.274 106.83C119.469 107.282 118.512 107.182 117.656 106.981Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_212" d="M118.714 105.926C119.922 105.725 121.18 106.278 121.281 107.533C121.382 108.789 120.476 109.542 119.167 109.693C117.908 109.793 116.398 109.04 115.341 108.337C116.046 107.132 117.405 106.127 118.714 105.926Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_213" d="M118.26 110.295C118.613 109.894 118.965 109.542 119.317 109.14C119.821 109.341 120.274 109.592 120.777 109.843C120.022 110.346 119.116 110.396 118.26 110.295Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_214" d="M119.066 109.191C120.223 108.789 121.532 109.14 121.885 110.346C122.237 111.551 121.482 112.405 120.173 112.656C118.965 112.907 117.304 112.455 116.146 111.802C116.8 110.597 117.807 109.643 119.066 109.191Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_215" d="M116.146 111.852C115.492 111.551 114.938 110.999 114.787 110.245C114.636 109.542 114.888 108.889 115.29 108.287" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_216" d="M117.606 115.167C116.901 114.966 116.247 114.464 115.945 113.761C115.643 113.058 115.743 112.405 116.146 111.802" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_217" d="M115.542 109.291C115.794 108.889 116.045 108.487 116.347 108.186C116.901 108.437 117.405 108.738 117.958 108.939C117.455 109.19 117.002 109.492 116.599 109.894C116.247 109.693 115.894 109.492 115.542 109.291Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_218" d="M114.435 111.199C114.636 111.5 114.888 111.802 115.19 112.053C115.391 111.651 115.643 111.199 115.844 110.797C115.643 110.647 115.542 110.396 115.441 110.145C115.139 110.496 114.737 110.848 114.435 111.199Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        </g>
        <path id="Vector_219" d="M116.549 112.756C116.75 112.354 116.952 111.953 117.203 111.551C117.757 111.752 118.311 111.902 118.864 112.003C118.411 112.354 118.059 112.706 117.707 113.158C117.304 113.108 116.901 112.957 116.549 112.756Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_220" d="M119.418 113.409C119.67 113.007 119.871 112.555 120.173 112.154C120.727 112.354 121.281 112.455 121.834 112.606C121.13 113.208 120.374 113.409 119.418 113.409Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_221" d="M120.022 112.204C121.079 111.752 122.539 111.852 123.143 112.957C123.747 114.062 123.194 115.016 121.885 115.468C120.677 115.87 118.864 115.669 117.606 115.167C117.958 113.961 118.814 112.706 120.022 112.204Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_222" d="M121.281 116.322C121.432 115.87 121.633 115.367 121.784 114.916C122.388 115.016 122.992 115.116 123.596 115.167C123.093 115.92 122.137 116.171 121.281 116.322Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_223" d="M121.633 115.016C122.69 114.363 124.15 114.313 125.006 115.368C125.862 116.422 125.459 117.477 124.15 118.08C122.892 118.632 121.18 118.682 119.771 118.28C120.022 117.025 120.475 115.769 121.633 115.016Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_224" d="M119.771 118.33C119.016 118.18 118.261 117.828 117.807 117.175C117.354 116.522 117.354 115.87 117.606 115.167" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_225" d="M122.137 121.294C121.381 121.243 120.626 120.942 120.173 120.339C119.72 119.737 119.569 119.034 119.771 118.331" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_226" d="M118.21 116.071C118.311 115.619 118.411 115.167 118.562 114.765C119.166 114.865 119.77 115.016 120.425 115.016C120.072 115.418 119.72 115.82 119.519 116.322C119.066 116.271 118.613 116.171 118.21 116.071Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_227" d="M117.807 118.23C118.16 118.481 118.462 118.682 118.864 118.833C118.965 118.381 119.066 117.879 119.116 117.427C118.864 117.326 118.613 117.125 118.411 116.925C118.26 117.377 118.059 117.778 117.807 118.23Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_228" d="M120.475 119.134C120.525 118.682 120.576 118.23 120.727 117.828C121.331 117.879 121.985 117.929 122.589 117.828C122.287 118.28 122.036 118.732 121.784 119.234C121.331 119.285 120.928 119.285 120.475 119.134Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_229" d="M123.596 119.034C123.747 118.582 123.898 118.079 123.999 117.627C124.603 117.678 125.207 117.678 125.861 117.728C125.358 118.481 124.452 118.782 123.596 119.034Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_230" d="M123.797 117.728C124.804 116.924 126.365 116.774 127.221 117.778C128.127 118.833 127.573 120.088 126.365 120.791C125.207 121.444 123.445 121.444 122.136 121.294C122.438 119.938 122.69 118.632 123.797 117.728Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_231" d="M125.761 121.846C125.962 121.294 126.113 120.791 126.264 120.239C126.818 120.289 127.372 120.339 127.925 120.339C127.472 121.143 126.667 121.545 125.761 121.846Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_232" d="M126.113 120.339C127.019 119.435 128.479 119.285 129.234 120.44C129.989 121.595 129.335 122.951 128.127 123.604C126.969 124.257 125.408 124.257 124.1 124.106C124.502 122.8 125.106 121.344 126.113 120.339Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_233" d="M124.1 124.156C123.445 124.106 122.791 123.855 122.388 123.252C121.985 122.65 121.985 121.947 122.136 121.294" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_234" d="M125.912 127.019C125.258 126.969 124.654 126.717 124.301 126.115C123.949 125.512 123.949 124.809 124.1 124.156" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_235" d="M122.741 122.097C122.841 121.595 122.992 121.143 123.093 120.641C123.748 120.641 124.352 120.641 125.006 120.54C124.654 121.042 124.352 121.545 124.05 122.097C123.597 122.147 123.194 122.147 122.741 122.097Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_236" d="M122.388 124.307C122.69 124.508 122.992 124.658 123.294 124.759C123.395 124.257 123.496 123.805 123.596 123.302C123.345 123.252 123.143 123.102 122.992 122.901C122.791 123.353 122.59 123.855 122.388 124.307Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
      </g>
      <g id="Group_30" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
        <g id="Group_31" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
          <path id="Vector_237" d="M101.246 84.633C101.095 85.1352 100.893 85.5871 100.692 86.0894C100.138 85.8885 99.5343 85.6876 98.9302 85.6374C99.3329 85.1854 99.6853 84.7334 100.038 84.2312C100.44 84.2814 100.843 84.4321 101.246 84.633Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_238" d="M98.326 84.1306C98.0743 84.6328 97.8226 85.135 97.5709 85.6372C97.0171 85.4866 96.4634 85.2857 95.9097 85.135C96.4634 84.4319 97.3695 84.2311 98.326 84.1306Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_239" d="M97.722 85.537C96.5138 86.2401 95.1546 85.989 94.5506 84.6832C93.9465 83.3775 94.5506 82.2224 95.9097 81.7202C97.1682 81.218 98.8295 81.6198 100.138 82.0215C99.6349 83.4277 99.0308 84.7837 97.722 85.537Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_240" d="M96.6144 80.8162C96.4131 81.3184 96.1614 81.8206 95.96 82.3228C95.4062 82.2223 94.8525 82.1219 94.3491 81.9712C94.8525 81.2682 95.7587 81.017 96.6144 80.8162Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_241" d="M96.111 82.2223C95.0538 82.9254 93.6443 82.9254 93.0402 81.7201C92.4361 80.5148 93.0402 79.4602 94.2987 78.8575C95.5069 78.3051 97.0674 78.3553 98.3259 78.7069C97.9735 80.0126 97.2688 81.4188 96.111 82.2223Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_242" d="M98.376 78.707C99.0807 78.8577 99.6848 79.2092 100.088 79.9123C100.44 80.6154 100.39 81.3687 100.138 82.0718" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_243" d="M96.4634 75.5933C97.1681 75.6435 97.8226 75.995 98.2253 76.6479C98.628 77.3008 98.628 78.0039 98.3763 78.707" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_244" d="M99.5847 81.0672C99.4336 81.5694 99.333 82.0716 99.1316 82.5236C98.5275 82.4231 97.9234 82.2725 97.3193 82.2725C97.7221 81.8205 98.0241 81.3183 98.3261 80.7659C98.7792 80.8663 99.1819 80.9667 99.5847 81.0672Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_245" d="M100.189 78.8074C99.8867 78.5563 99.635 78.3555 99.2826 78.2048C99.1316 78.707 98.9806 79.159 98.8799 79.6612C99.1316 79.7616 99.3329 79.9625 99.4839 80.1634C99.6853 79.7114 99.937 79.2594 100.189 78.8074Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        </g>
        <g id="Group_32" data-sentry-element="g" data-sentry-source-file="pencil-mechanical.tsx">
          <path id="Vector_246" d="M97.8223 77.8531C97.7216 78.3051 97.5706 78.8074 97.3693 79.2594C96.8155 79.1589 96.2114 79.2091 95.6577 79.2594C96.0101 78.8074 96.3625 78.3051 96.6142 77.7527C97.0672 77.7025 97.4699 77.7025 97.8223 77.8531Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_247" d="M95.0031 77.8533C94.8017 78.4057 94.6004 78.9079 94.3487 79.4101C93.7949 79.3599 93.2915 79.2093 92.7378 79.159C93.2915 78.4057 94.1473 78.1044 95.0031 77.8533Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_248" d="M94.55 79.3095C93.5936 80.0629 92.2344 80.1131 91.5297 79.0584C90.8249 78.0038 91.278 76.899 92.4358 76.1959C93.4929 75.4928 95.1541 75.4928 96.463 75.6435C96.3623 76.9994 95.6072 78.506 94.55 79.3095Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_249" d="M92.9897 75.1414C92.8386 75.6436 92.6876 76.1458 92.5869 76.648C92.0332 76.648 91.5298 76.6982 90.9761 76.6982C91.3788 75.9449 92.1842 75.4929 92.9897 75.1414Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_250" d="M92.738 76.5476C91.8822 77.4013 90.5734 77.7027 89.7176 76.7987C88.8618 75.8947 89.1135 74.7397 90.1706 73.9362C91.1774 73.1828 92.8387 72.8313 94.1475 72.8313C94.1475 74.2375 93.6441 75.5934 92.738 76.5476Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_251" d="M94.1475 72.8312C94.8522 72.781 95.6073 73.0321 96.0603 73.6347C96.5134 74.1872 96.614 74.8903 96.5134 75.5934" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_252" d="M91.2783 70.5211C91.9831 70.3704 92.8389 70.5211 93.3926 71.0233C93.9464 71.4752 94.1981 72.1281 94.0974 72.8311" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_253" d="M95.8084 74.8399C95.8084 75.2919 95.7581 75.7439 95.6071 76.1959C95.003 76.1959 94.399 76.1958 93.8452 76.2963C94.1473 75.8443 94.4493 75.3421 94.6003 74.8399C95.003 74.7897 95.4057 74.7897 95.8084 74.8399Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
          <path id="Vector_254" d="M95.9595 72.6303C95.6071 72.4294 95.2547 72.329 94.9023 72.2285C94.9023 72.6805 94.9023 73.1827 94.9023 73.6347C95.154 73.6849 95.3554 73.8355 95.5568 74.0364C95.6574 73.5844 95.8085 73.0822 95.9595 72.6303Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        </g>
        <path id="Vector_255" d="M93.3424 72.2285C93.3424 72.6805 93.3927 73.1325 93.3424 73.5844C92.7887 73.6849 92.1846 73.7853 91.6309 73.936C91.8826 73.4338 92.0336 72.9316 92.0839 72.3792C92.4866 72.2787 92.9397 72.2285 93.3424 72.2285Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_256" d="M90.473 72.9819C90.473 73.4841 90.473 73.9863 90.3723 74.4383C89.8185 74.5388 89.2647 74.6392 88.6606 74.7898C89.0634 73.8859 89.6675 73.3837 90.473 72.9819Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_257" d="M90.5234 74.2875C89.7683 75.1914 88.4595 75.7438 87.4023 75.0407C86.3452 74.3377 86.3955 73.2329 87.352 72.2285C88.2581 71.3245 89.9697 70.6717 91.2785 70.521C91.5302 71.7765 91.3288 73.2831 90.5234 74.2875Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_258" d="M87.5029 71.2241C87.6036 71.7263 87.6539 72.1783 87.7043 72.6805C87.1002 72.8311 86.5464 73.0822 85.9927 73.2831C86.0934 72.3791 86.7981 71.7263 87.5029 71.2241Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_259" d="M87.7544 72.5297C87.0999 73.5843 85.7911 74.2874 84.5829 73.7852C83.3748 73.283 83.2237 72.0777 84.1299 70.9729C84.9856 69.9183 86.4958 69.0645 87.9054 68.7632C88.3081 70.0187 88.5095 71.3244 87.7544 72.5297Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_260" d="M87.9053 68.7633C88.6604 68.5624 89.4658 68.5122 90.1706 68.8638C90.8753 69.2153 91.1774 69.818 91.278 70.5211" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_261" d="M84.4321 67.2065C85.1369 66.9052 85.9423 66.855 86.647 67.1563C87.3518 67.4576 87.7545 68.0602 87.9055 68.7633" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_262" d="M90.3217 70.0188C90.4224 70.4708 90.5734 70.8726 90.5734 71.3245C89.9693 71.5254 89.3653 71.6761 88.8115 71.9272C88.9625 71.425 89.0632 70.8726 89.0129 70.3201C89.4659 70.2197 89.919 70.1192 90.3217 70.0188Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_263" d="M89.7179 67.9597C89.3152 67.8592 88.9125 67.8592 88.5098 67.9095C88.6608 68.3614 88.7615 68.8134 88.9125 69.2653C89.2145 69.2151 89.4662 69.3156 89.7682 69.416C89.7179 68.9138 89.7179 68.4116 89.7179 67.9597Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_264" d="M86.8986 68.3113C87.0497 68.713 87.2007 69.165 87.3014 69.5668C86.6973 69.8179 86.1435 70.069 85.6401 70.4206C85.7408 69.8681 85.7408 69.3659 85.6905 68.8135C86.0932 68.6126 86.4959 68.4117 86.8986 68.3113Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_265" d="M84.2308 69.8682C84.3315 70.3704 84.4322 70.8224 84.5328 71.3246C83.9791 71.5757 83.4254 71.8268 82.8213 72.0779C82.8716 71.1739 83.5764 70.4708 84.2308 69.8682Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_266" d="M84.5828 71.1236C84.0291 72.3289 82.7706 73.1324 81.5121 72.6302C80.2536 72.128 80.1529 70.7219 80.908 69.5668C81.6128 68.462 83.1733 67.6585 84.4318 67.1562C84.7842 68.5122 85.1869 69.8179 84.5828 71.1236Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_267" d="M80.9587 68.3616C81.0594 68.914 81.1097 69.4664 81.2607 70.0188C80.7573 70.2197 80.2539 70.4708 79.7505 70.6717C79.8008 69.7677 80.3546 69.0144 80.9587 68.3616Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_268" d="M81.3613 69.868C80.9586 71.0733 79.7505 71.8769 78.5423 71.1738C77.3342 70.4707 77.2838 69.0143 78.0893 67.8592C78.8443 66.7544 80.2035 66.0513 81.4117 65.5491C81.6634 66.9552 81.8144 68.5121 81.3613 69.868Z" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_269" d="M81.4116 65.5491C82.0157 65.298 82.7205 65.2478 83.3245 65.5491C83.9286 65.8504 84.281 66.5033 84.432 67.2063" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_270" d="M78.4917 63.8417C79.0958 63.5906 79.7502 63.5404 80.304 63.892C80.9081 64.2435 81.2604 64.8963 81.3611 65.5492" stroke="#CFDBE2" stroke-width="2.8281" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_271" d="M83.5764 66.7544C83.7274 67.2064 83.8281 67.7086 83.9287 68.1606C83.375 68.4619 82.8213 68.7633 82.2676 69.165C82.3683 68.5624 82.4186 67.9597 82.3682 67.3068C82.7709 67.106 83.1233 66.9051 83.5764 66.7544Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
        <path id="Vector_272" d="M82.8716 64.595C82.5192 64.5448 82.1668 64.5448 81.8647 64.6452C82.0158 65.0972 82.1165 65.5994 82.2675 66.0514C82.5192 66.0012 82.7709 66.0514 82.9723 66.1518C82.9219 65.6496 82.8716 65.1474 82.8716 64.595Z" fill="#B4C7D1" data-sentry-element="path" data-sentry-source-file="pencil-mechanical.tsx" />
      </g>
    </g>
  </svg>;