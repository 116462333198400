import type { SVGProps } from "react";
export const Pencil = (props: SVGProps<SVGSVGElement>) => <svg xmlns="http://www.w3.org/2000/svg" width="231" height="153" viewBox="0 0 231 153" fill="none" {...props} data-sentry-element="svg" data-sentry-component="Pencil" data-sentry-source-file="pencil.tsx">
    <path d="M46.0524 43.9731C54.2703 29.8742 54.1128 14.4888 45.7007 9.60879C37.2885 4.7288 23.8072 12.2022 15.5893 26.3011C7.3714 40.3999 7.52887 55.7854 15.941 60.6654C24.3532 65.5453 37.8345 58.072 46.0524 43.9731Z" fill="#FF5252" data-sentry-element="path" data-sentry-source-file="pencil.tsx" />
    <path d="M174.2 152.512L203.96 101.455L45.6388 9.61144L15.8791 60.668L174.2 152.512Z" fill="#FFBB24" data-sentry-element="path" data-sentry-source-file="pencil.tsx" />
    <path d="M203.989 101.437L174.238 152.511L227.598 151.908C229.612 151.908 230.87 149.748 229.914 147.991L203.989 101.437Z" fill="#FFDBA6" data-sentry-element="path" data-sentry-source-file="pencil.tsx" />
    <path d="M214.208 141.563C212.144 145.078 210.684 148.694 209.828 152.159L227.598 151.958C229.612 151.958 230.87 149.799 229.914 148.041L221.306 132.523C218.688 134.984 216.272 138.047 214.208 141.563Z" fill="#494851" data-sentry-element="path" data-sentry-source-file="pencil.tsx" />
    <path d="M173.886 118.16C165.68 132.272 165.831 147.639 174.238 152.511L203.989 101.437C195.582 96.5654 182.091 104.048 173.886 118.16Z" fill="#FFDBA6" data-sentry-element="path" data-sentry-source-file="pencil.tsx" />
    <path d="M33.6894 71.0032L63.4402 19.9292L45.6703 9.63403L23.9738 31.1785L15.9194 60.708L33.6894 71.0032Z" fill="#FF5252" data-sentry-element="path" data-sentry-source-file="pencil.tsx" />
    <path d="M65.9503 55.5202C74.1682 41.4214 74.0107 26.036 65.5986 21.156C57.1864 16.276 43.7051 23.7494 35.4872 37.8482C27.2693 51.9471 27.4268 67.3325 35.839 72.2125C44.2511 77.0924 57.7324 69.6191 65.9503 55.5202Z" fill="#EBF0F7" data-sentry-element="path" data-sentry-source-file="pencil.tsx" />
    <path d="M45.0046 77.2884L74.6377 26.449L65.5423 21.1726L35.9091 72.0121L45.0046 77.2884Z" fill="#EBF0F7" data-sentry-element="path" data-sentry-source-file="pencil.tsx" />
    <path d="M75.0261 60.8161C83.244 46.7172 83.0865 31.3318 74.6743 26.4519C66.2622 21.5719 52.7808 29.0452 44.5629 43.1441C36.345 57.243 36.5025 72.6284 44.9147 77.5084C53.3268 82.3883 66.8082 74.915 75.0261 60.8161Z" fill="#FFBB24" data-sentry-element="path" data-sentry-source-file="pencil.tsx" />
    <path opacity="0.23" d="M181.336 107.011L53.02 32.5344" stroke="#C0C9D8" stroke-width="8.6789" stroke-miterlimit="10" data-sentry-element="path" data-sentry-source-file="pencil.tsx" />
    <path opacity="0.23" d="M227.598 151.958C228.655 151.958 229.511 151.356 229.914 150.552L169.808 127.15L11.5399 35.2466C7.81471 46.3955 9.32491 56.8413 15.9698 60.658L33.7397 70.9531L174.288 152.511L209.879 152.109L227.598 151.958Z" fill="#C0C9D8" data-sentry-element="path" data-sentry-source-file="pencil.tsx" />
  </svg>;