import type { SVGProps } from "react";
export const Lego = (props: SVGProps<SVGSVGElement>) => <svg width="178" height="184" viewBox="0 0 178 184" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="Lego" data-sentry-source-file="lego.tsx">
    <path d="M176.181 95.9617L113.206 59.6525C112.4 59.2005 111.394 59.2005 110.538 59.6525L83.9585 75.0701L152.169 114.393L176.131 100.482C177.943 99.4771 177.943 96.9661 176.181 95.9617Z" fill="#E9EFF4" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M161.129 22.4392L124.935 1.54761L88.6904 22.389L124.935 43.2806L161.129 22.4392Z" fill="#FC5454" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M124.935 116L161.129 95.1082V22.4395L124.935 43.2809V116Z" fill="#F24444" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M124.935 116L88.6904 95.1081V22.3892L124.935 43.2808V116Z" fill="#EDB435" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M141.044 18.8236V9.43237H108.675V19.828C108.675 25.1513 115.924 29.4702 124.935 29.4702C133.895 29.4702 141.195 25.1513 141.195 19.828C141.144 19.5266 141.144 19.1751 141.044 18.8236Z" fill="#F24444" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M124.935 19.8781C133.915 19.8781 141.195 15.5611 141.195 10.2358C141.195 4.91051 133.915 0.593506 124.935 0.593506C115.955 0.593506 108.675 4.91051 108.675 10.2358C108.675 15.5611 115.955 19.8781 124.935 19.8781Z" fill="#FF6C6C" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path opacity="0.46" d="M88.6904 56.0366L124.935 76.878L161.129 56.0366" stroke="#FCC947" stroke-width="2.7956" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M175.224 127.601L90.5026 78.7865C89.3951 78.1337 88.036 78.1337 86.9285 78.7865L2.20661 127.55C-0.159364 128.906 -0.159364 132.372 2.20661 133.728L86.9285 182.542C88.036 183.195 89.3951 183.195 90.5026 182.542L175.224 133.778C177.59 132.422 177.59 128.957 175.224 127.601Z" fill="#E9EFF4" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M162.287 94.8068L88.6905 52.4209L15.144 94.7566L88.6905 137.193L162.287 94.8068Z" fill="#41C9F9" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M88.6904 171.041L162.287 128.655V94.7566L88.6904 137.142V171.041Z" fill="#38B4F2" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M88.6905 171.041L15.144 128.655V94.7566L88.6905 137.142V171.041Z" fill="#2397E5" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path opacity="0.64" d="M88.6905 94.4551L52.4962 116.301V150.2L15.144 128.655V94.7565L52.4962 72.9106L88.6905 94.4551Z" fill="#C5D1DD" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M44.2591 134.22C48.1048 132.281 49.3206 126.956 46.9748 122.327C44.6291 117.699 39.61 115.519 35.7644 117.459C31.9188 119.399 30.7029 124.723 33.0487 129.352C35.3944 133.98 40.4135 136.16 44.2591 134.22Z" fill="white" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M104.849 112.685V103.294H72.481V113.69C72.481 119.013 79.7299 123.332 88.7407 123.332C97.7012 123.332 105 119.013 105 113.69C104.95 113.338 104.9 113.037 104.849 112.685Z" fill="#38B4F2" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M88.6904 113.69C97.6704 113.69 104.95 109.373 104.95 104.047C104.95 98.722 97.6704 94.405 88.6904 94.405C79.7104 94.405 72.4307 98.722 72.4307 104.047C72.4307 109.373 79.7104 113.69 88.6904 113.69Z" fill="#52DEFF" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M124.935 43.1803L88.6904 22.3389L52.4961 43.1803L88.6904 64.0217L124.935 43.1803Z" fill="#FC5454" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M88.6904 94.6562L124.935 73.8148V43.1804L88.6904 64.072V94.6562Z" fill="#F24444" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M88.6904 94.6562L52.4961 73.8148V43.1804L88.6904 64.0218V94.6562Z" fill="#E23737" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M104.849 39.6146V30.2234H72.481V40.619C72.481 45.9424 79.7299 50.2613 88.7407 50.2613C97.7012 50.2613 105 45.9424 105 40.619C104.95 40.2675 104.9 39.9159 104.849 39.6146Z" fill="#F24444" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M88.6904 40.6191C97.6704 40.6191 104.95 36.3021 104.95 30.9768C104.95 25.6515 97.6704 21.3345 88.6904 21.3345C79.7104 21.3345 72.4307 25.6515 72.4307 30.9768C72.4307 36.3021 79.7104 40.6191 88.6904 40.6191Z" fill="#FF6C6C" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M141.648 91.1407V81.7495H109.279V92.1451C109.279 97.4685 116.528 101.787 125.539 101.787C134.5 101.787 141.799 97.4685 141.799 92.1451C141.748 91.8438 141.698 91.4923 141.648 91.1407Z" fill="#38B4F2" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M125.489 92.1953C134.469 92.1953 141.749 87.8783 141.749 82.553C141.749 77.2277 134.469 72.9106 125.489 72.9106C116.509 72.9106 109.229 77.2277 109.229 82.553C109.229 87.8783 116.509 92.1953 125.489 92.1953Z" fill="#52DEFF" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M25.4637 159.189C31.1353 159.189 35.733 156.469 35.733 153.113C35.733 149.756 31.1353 147.036 25.4637 147.036C19.7921 147.036 15.1943 149.756 15.1943 153.113C15.1943 156.469 19.7921 159.189 25.4637 159.189Z" fill="#F2F5F7" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path opacity="0.64" d="M44.7438 127.701V145.78L28.182 154.669L18.8691 148.743L35.4813 140.407V126.596L44.7438 127.701Z" fill="#C5D1DD" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M25.4636 155.272C21.8391 155.272 18.9194 152.359 18.9194 148.743V142.265C18.9194 134.732 22.997 127.701 29.5411 123.935L35.0282 120.771C38.1492 118.963 42.1764 120.017 43.9887 123.181C45.8009 126.295 44.7438 130.312 41.5723 132.12L36.0853 135.284C33.5683 136.741 32.0581 139.402 32.0581 142.265V148.743C32.0078 152.359 29.0881 155.272 25.4636 155.272Z" fill="#FF7171" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path opacity="0.37" d="M26.6215 133.778C28.9371 128.956 43.3343 122.478 43.435 122.378C41.4717 119.816 37.8473 119.063 34.9779 120.72L29.4908 123.884C22.9467 127.651 18.8691 134.682 18.8691 142.215V148.693C18.8691 152.309 21.7889 155.222 25.4133 155.222C25.4637 155.272 24.3058 138.599 26.6215 133.778Z" fill="#F26161" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M68.6551 91.1407V81.7495H36.2866V92.1451C36.2866 97.4685 43.5356 101.787 52.5464 101.787C61.5069 101.787 68.8061 97.4685 68.8061 92.1451C68.7558 91.8438 68.7054 91.4923 68.6551 91.1407Z" fill="#2397E5" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
    <path d="M52.4961 92.1953C61.4761 92.1953 68.7559 87.8783 68.7559 82.553C68.7559 77.2277 61.4761 72.9106 52.4961 72.9106C43.5161 72.9106 36.2363 77.2277 36.2363 82.553C36.2363 87.8783 43.5161 92.1953 52.4961 92.1953Z" fill="#41C9F9" data-sentry-element="path" data-sentry-source-file="lego.tsx" />
  </svg>;