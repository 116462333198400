import type { SVGProps } from "react";
export const ChibiRunning = (props: SVGProps<SVGSVGElement>) => <svg width="67" height="81" viewBox="0 0 67 81" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="ChibiRunning" data-sentry-source-file="chibi-running.tsx">
    <path opacity="0.12" d="M37.2956 80.9644C52.4754 80.9644 64.7811 73.657 64.7811 64.6429C64.7811 55.6287 52.4754 48.3213 37.2956 48.3213C22.1158 48.3213 9.81006 55.6287 9.81006 64.6429C9.81006 73.657 22.1158 80.9644 37.2956 80.9644Z" fill="#808080" data-sentry-element="path" data-sentry-source-file="chibi-running.tsx" />
    <path d="M10.6658 30.1412C7.79643 27.9818 4.92705 25.7721 2.05767 23.6126C0.648153 22.558 -0.711019 24.9184 0.64816 25.973C3.51754 28.1325 6.38692 30.3421 9.25629 32.5016C10.6658 33.6064 12.025 31.1959 10.6658 30.1412Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-running.tsx" />
    <path d="M28.8887 46.8147C28.033 49.8781 26.5228 52.6402 25.2139 55.553C24.6602 56.8085 24.1064 58.1142 23.7037 59.4701C23.3514 60.575 23.4017 62.5838 22.7473 63.4878C23.15 63.2869 23.5527 63.0358 23.9554 62.8349C23.2003 62.7847 22.4956 62.634 21.7908 62.4332C20.0793 62.0314 19.3242 64.6931 21.0861 65.0949C22.4452 65.3962 24.1064 66.0992 25.1636 64.894C25.7677 64.1909 25.667 63.4375 25.8683 62.5336C26.0697 61.3785 26.4221 60.2737 26.8248 59.1688C28.2343 55.2517 30.4996 51.686 31.6574 47.6182C32.0098 45.8102 29.3921 45.1072 28.8887 46.8147Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-running.tsx" />
    <path d="M43.5376 53.9459C45.1485 56.708 46.9104 60.1731 49.8301 61.8806C50.6859 62.3828 51.6927 62.6841 52.6491 62.8348C53.2532 62.9352 53.9076 62.9854 54.5117 63.0356C54.7634 63.0356 55.1158 63.1361 55.3675 63.0859C56.0219 63.1863 55.9715 63.1361 55.3171 62.9352C54.7634 62.5837 54.4614 62.7845 54.3103 63.4876C53.3539 64.9942 55.7198 66.3502 56.6763 64.8938C57.4314 63.6885 58.7906 61.8806 57.1294 60.7255C56.1226 60.0225 54.26 60.374 53.0518 60.1229C49.4274 59.4701 47.5648 55.4023 45.9036 52.5397C44.9975 51.0332 42.6315 52.389 43.5376 53.9459Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-running.tsx" />
    <path d="M37.4463 56.6577C53.1266 56.6577 65.838 43.9765 65.838 28.3335C65.838 12.6904 53.1266 0.00927734 37.4463 0.00927734C21.7661 0.00927734 9.05469 12.6904 9.05469 28.3335C9.05469 43.9765 21.7661 56.6577 37.4463 56.6577Z" fill="#FDDA44" data-sentry-element="path" data-sentry-source-file="chibi-running.tsx" />
    <path d="M54.2097 12.6649C61.4586 19.7459 63.4722 29.4886 61.2572 31.7486C59.0423 34.0085 51.3906 30.0913 44.1417 22.96C36.8928 15.879 32.8153 8.2957 35.0302 6.03579C37.1948 3.77588 46.9608 5.5336 54.2097 12.6649Z" fill="#FDE272" data-sentry-element="path" data-sentry-source-file="chibi-running.tsx" />
    <path d="M17.5282 35.6065C18.6779 34.9868 18.8062 33.0008 17.815 31.1706C16.8237 29.3405 15.0881 28.3592 13.9385 28.979C12.7889 29.5987 12.6605 31.5847 13.6518 33.4148C14.6431 35.245 16.3786 36.2262 17.5282 35.6065Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-running.tsx" />
    <path d="M44.3833 38.9947C45.323 37.4547 44.5446 35.271 42.6448 34.1172C40.7449 32.9633 38.443 33.2764 37.5032 34.8164C36.5635 36.3563 37.3419 38.5401 39.2418 39.6939C41.1417 40.8477 43.4436 40.5346 44.3833 38.9947Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-running.tsx" />
    <path d="M55.5187 42.4955C58.7404 38.3272 62.3649 34.5105 66.2914 30.9951C67.6003 29.84 65.6873 27.8814 64.3282 29.0365C60.4017 32.5519 56.7772 36.3686 53.5554 40.5369C52.5486 41.943 54.4616 43.9016 55.5187 42.4955Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-running.tsx" />
    <path d="M30.6005 37.0216C29.342 39.1811 29.6943 41.893 31.4562 43.6507C32.5637 44.7556 34.2753 43.0481 33.1678 41.9432C32.161 40.9388 31.9596 39.4322 32.6644 38.2269C33.4698 36.9212 31.4059 35.7159 30.6005 37.0216Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-running.tsx" />
    <path d="M30.7514 38.2772C28.6874 37.5741 26.5732 36.9715 24.4589 36.419C22.9487 36.0173 22.3446 38.3274 23.8045 38.7292C25.9188 39.2816 27.9827 39.8842 30.097 40.5873C31.5568 41.0895 32.2112 38.7794 30.7514 38.2772Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-running.tsx" />
    <path d="M21.9417 35.063C22.4954 36.5696 23.0995 38.2268 21.992 39.633C21.0356 40.8383 23.1498 42.0436 24.056 40.8383C25.5158 38.9299 24.9621 36.4691 24.207 34.4101C23.7036 32.9537 21.3879 33.6066 21.9417 35.063Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-running.tsx" />
    <path d="M38.6046 32.4515C40.3665 34.5607 43.0848 35.7659 45.8535 35.6153C47.6154 35.5651 47.6154 32.803 45.8535 32.9034C43.7392 33.0039 41.927 32.1501 40.5678 30.5431C39.41 29.1872 37.4971 31.0955 38.6046 32.4515Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-running.tsx" />
    <path d="M11.6224 29.6392C13.8877 30.2419 16.4047 29.4886 17.9149 27.6807C19.0224 26.3247 17.1095 24.3662 15.9517 25.7221C15.0455 26.8269 13.7367 27.3291 12.3272 26.9776C10.6156 26.5758 9.91088 29.1873 11.6224 29.6392Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-running.tsx" />
    <path opacity="0.4" d="M47.3205 43.8511C50.4039 40.2044 49.3201 34.2327 44.8997 30.5128C40.4793 26.7929 34.3963 26.7335 31.3129 30.3801C28.2296 34.0267 29.3134 39.9985 33.7338 43.7184C38.1541 47.4383 44.2372 47.4977 47.3205 43.8511Z" fill="#52DEFF" data-sentry-element="path" data-sentry-source-file="chibi-running.tsx" />
    <path opacity="0.4" d="M22.6267 38.5534C25.7101 34.9068 24.6262 28.9351 20.2058 25.2152C15.7855 21.4953 9.70248 21.4359 6.61909 25.0825C3.53571 28.7291 4.61955 34.7009 9.03991 38.4207C13.4603 42.1406 19.5433 42.2001 22.6267 38.5534Z" fill="#52DEFF" data-sentry-element="path" data-sentry-source-file="chibi-running.tsx" />
  </svg>;