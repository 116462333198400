import type { SVGProps } from "react";
export const ChibiRocket = (props: SVGProps<SVGSVGElement>) => <svg width="94" height="101" viewBox="0 0 94 101" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} data-sentry-element="svg" data-sentry-component="ChibiRocket" data-sentry-source-file="chibi-rocket.tsx">
    <path d="M8.45141 44.4164L2.76303 45.0191C1.35352 45.1697 0.548056 43.4623 1.60519 42.4579L2.86368 41.2526C3.46776 40.7001 3.46778 39.7459 2.91404 39.1433L0.598395 36.6825C-0.20704 35.8288 0.246036 34.4226 1.40385 34.2217L5.17933 33.5186C5.78341 33.4182 6.28678 32.916 6.38746 32.3134L6.99155 28.5971C7.19291 27.3918 8.65276 26.9398 9.50853 27.7936L17.1098 35.427C17.7139 36.0297 17.6636 37.0341 17.0092 37.5865L9.30719 44.0649C9.05549 44.2658 8.75345 44.3662 8.45141 44.4164Z" fill="#F4504D" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M9.81085 42.4076L6.13604 42.8094C5.22993 42.9098 4.72653 41.805 5.38094 41.1521L6.1864 40.3988C6.58911 40.0473 6.5891 39.4446 6.23672 39.0429L4.72654 37.4359C4.22314 36.8834 4.52518 35.9795 5.22993 35.8288L7.64622 35.3768C8.04894 35.3266 8.35098 34.9751 8.40132 34.5733L8.7537 32.1627C8.85438 31.4094 9.81084 31.0579 10.3646 31.6103L15.2978 36.5319C15.7006 36.9336 15.6502 37.5865 15.2475 37.9381L10.2639 42.1565C10.1632 42.3072 10.0122 42.4076 9.81085 42.4076Z" fill="#FDDA44" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M23.7547 36.3813L18.0663 36.984C16.6568 37.1346 15.8513 35.4272 16.9085 34.4227L18.167 33.2174C18.7711 32.665 18.771 31.7108 18.2173 31.1082L15.9017 28.6474C15.0963 27.7937 15.5493 26.3875 16.7071 26.1866L20.4826 25.4835C21.0867 25.3831 21.5901 24.8809 21.6908 24.2782L22.2949 20.5619C22.4962 19.3566 23.9561 18.9047 24.8119 19.7584L32.4132 27.3919C33.0172 27.9946 32.9669 28.999 32.3125 29.5514L24.6105 36.0298C24.3588 36.2307 24.0567 36.3311 23.7547 36.3813Z" fill="#F4504D" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M25.1141 34.3725L21.4393 34.7743C20.5331 34.8747 20.0298 33.7699 20.6842 33.117L21.4896 32.3637C21.8923 32.0121 21.8923 31.4095 21.5399 31.0077L20.0298 29.4007C19.5264 28.8483 19.8284 27.9443 20.5332 27.7937L22.9495 27.3417C23.3522 27.2914 23.6542 26.9399 23.7045 26.5382L24.0569 24.1276C24.1576 23.3743 25.1141 23.0227 25.6678 23.5751L30.6011 28.4967C31.0038 28.8985 30.9534 29.5514 30.5507 29.9029L25.5671 34.1214C25.4664 34.2721 25.3154 34.3725 25.1141 34.3725Z" fill="#FDDA44" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M34.4268 27.5427L39.4105 33.318L31.5071 44.5674L24.1575 41.1524C21.8419 39.5453 22.1942 35.0757 24.9126 31.2088C27.631 27.3418 31.7085 25.5339 34.0241 27.1409C34.1751 27.2916 34.3261 27.392 34.4268 27.5427Z" fill="#A6B2BA" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M31.5073 44.5674L26.9767 42.4582C26.0706 40.3489 26.725 36.9842 28.8393 33.971C30.9032 31.0582 33.7222 29.3005 35.9875 29.3005L39.4106 33.2679L31.5073 44.5674Z" fill="#9EACB5" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M42.5315 28.8986C41.9778 28.4968 41.273 28.3964 40.6186 28.5973C38.454 29.2501 34.1751 31.0078 31.0037 35.377C27.5805 40.0475 27.3792 44.9691 27.4799 47.2792C27.5302 47.9321 27.8826 48.5849 28.4363 48.9867L53.5559 66.5638C59.0933 70.4307 69.4634 70.9832 73.3395 65.4589C77.2157 59.9347 73.1886 50.3929 67.6512 46.5259L42.5315 28.8986Z" fill="#B6BFC6" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M79.1238 70.4181C87.0127 59.202 89.183 47.1521 83.9712 43.5038C78.7595 39.8555 68.1394 45.9903 60.2504 57.2064C52.3615 68.4225 50.1913 80.4724 55.403 84.1207C60.6147 87.7691 71.2349 81.6342 79.1238 70.4181Z" fill="#2590CC" stroke="white" stroke-width="5" stroke-miterlimit="10" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path opacity="0.18" d="M67.6509 67.4678C64.8319 68.1207 62.1638 67.1162 61.9625 66.2625C61.7611 65.4087 63.8754 64.1532 66.6944 63.4501C69.5134 62.7972 71.98 62.9479 72.1814 63.8519C72.3828 64.7056 70.4699 66.8149 67.6509 67.4678Z" fill="#52DEFF" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path opacity="0.44" d="M28.7789 38.0687L25.3091 43.002L56.2152 64.6368L59.685 59.7036L28.7789 38.0687Z" fill="white" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path opacity="0.44" d="M29.8677 34.9903L29.0581 36.1414L59.9642 57.7763L60.7738 56.6252L29.8677 34.9903Z" fill="white" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M53.6064 63.2494L53.1533 63.9023L48.4717 60.638L49.0254 59.8345C49.5791 59.0811 51.039 59.1816 52.3479 60.0856C53.6567 60.9895 54.2608 62.3455 53.707 63.0988C53.707 63.1992 53.6567 63.1992 53.6064 63.2494Z" fill="#EDB435" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M53.1843 63.9034C53.7272 63.1315 53.1158 61.7696 51.8185 60.8615C50.5213 59.9534 49.0295 59.843 48.4865 60.6149C47.9436 61.3869 48.555 62.7489 49.8523 63.6569C51.1495 64.565 52.6413 64.6754 53.1843 63.9034Z" fill="#FFBB24" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M20.6337 33.0165L25.9697 39.1936L17.4623 51.2465L9.60922 47.5804C7.14256 45.8729 7.49493 41.102 10.4146 36.9337C13.3344 32.8156 17.6636 30.857 20.1806 32.5645C20.382 32.7654 20.4827 32.8658 20.6337 33.0165Z" fill="#C0C7CC" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M17.5128 51.2967L12.6298 49.0368C11.6733 46.7768 12.3781 43.2112 14.6434 39.9469C16.8584 36.8332 19.8788 34.9249 22.2951 34.9751L25.9699 39.2438L17.5128 51.2967Z" fill="#A6B2BA" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M29.2919 34.4729C28.6878 34.0711 27.9327 33.9204 27.228 34.1213C24.9123 34.8244 20.3314 36.6826 16.9083 41.353C13.2335 46.3248 13.0321 51.598 13.1328 54.109C13.1831 54.8121 13.5355 55.5152 14.1396 55.9169L41.0714 74.7495C47.0115 78.9178 58.0863 79.5204 62.2645 73.5442C66.4427 67.6182 62.1135 57.4235 56.1734 53.2553L29.2919 34.4729Z" fill="#C0C7CC" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M68.4857 78.9133C76.9335 66.9026 79.2617 54.0018 73.6858 50.0986C68.11 46.1954 56.7415 52.7678 48.2937 64.7785C39.8459 76.7893 37.5177 89.6901 43.0936 93.5933C48.6694 97.4965 60.0379 90.924 68.4857 78.9133Z" fill="#2397E5" stroke="white" stroke-width="5" stroke-miterlimit="10" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path opacity="0.18" d="M55.4185 76.2562C51.3913 77.2104 47.5655 75.8042 47.2635 74.5487C46.9614 73.2932 49.9818 71.4853 54.0593 70.5311C58.0865 69.5769 61.6103 69.828 61.9123 71.0835C62.2144 72.339 59.4457 75.302 55.4185 76.2562Z" fill="#52DEFF" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path opacity="0.44" d="M14.5889 44.2812L10.8589 49.5845L43.949 72.7482L47.6791 67.445L14.5889 44.2812Z" fill="white" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path opacity="0.44" d="M15.7517 40.9908L14.8843 42.2241L47.9745 65.3879L48.8419 64.1546L15.7517 40.9908Z" fill="white" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M41.1725 71.2842L40.7195 71.9371L35.7358 68.4217L36.3399 67.568C36.8936 66.7645 38.5045 66.8649 39.9141 67.8191C41.3236 68.7733 41.978 70.2296 41.3739 71.0834C41.2732 71.1838 41.2229 71.234 41.1725 71.2842Z" fill="#37ADEF" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M40.7505 71.9971C41.3254 71.1797 40.6661 69.7293 39.2778 68.7575C37.8895 67.7857 36.298 67.6605 35.7231 68.4778C35.1482 69.2952 35.8076 70.7456 37.1959 71.7174C38.5842 72.6892 40.1756 72.8144 40.7505 71.9971Z" fill="#41C9F9" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M79.1285 12.3259C74.9503 11.5726 70.8225 11.6228 66.6946 12.577C64.9831 12.9788 65.6879 15.5902 67.3994 15.2387C71.0742 14.4351 74.6986 14.3347 78.3734 14.9876C80.085 15.2889 80.8401 12.6774 79.1285 12.3259Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M73.0375 38.6913C74.4973 29.8525 73.4401 19.0049 68.8593 12.5265C63.624 5.14415 59.3955 9.86487 53.5561 14.7362" stroke="#A3B0B2" stroke-width="7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M47.6155 11.6729C44.8972 10.5178 42.2796 9.21209 39.6619 7.85614C37.2456 6.55041 33.9232 5.19447 32.0103 3.18566C32.111 3.63764 32.262 4.08963 32.3627 4.54161C32.4633 3.68787 32.8661 2.93454 33.1681 2.13101C33.8225 0.473744 31.1545 -0.229322 30.5001 1.42795C29.9967 2.68346 29.2416 3.98918 30.047 5.19447C30.9028 6.5002 33.3695 7.50459 34.6279 8.2579C38.3531 10.4676 42.2292 12.4262 46.206 14.1337C47.8169 14.7363 49.2264 12.376 47.6155 11.6729Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M35.7355 27.4421C32.3628 25.5337 28.99 23.6756 25.6172 21.7672C23.956 20.813 22.2445 19.8588 20.5833 18.9047C19.1738 18.1011 16.6065 17.3981 17.2105 15.4897C17.7643 13.7822 15.0963 13.0791 14.5425 14.7866C14.0895 16.243 13.4351 17.4985 14.7439 18.6536C16.3548 20.1602 18.9724 21.1143 20.8853 22.2192C25.3656 24.7804 29.8458 27.2914 34.3764 29.8024C35.8865 30.7064 37.2457 28.3461 35.7355 27.4421Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M48.5219 60.7884C64.2022 60.7884 76.9135 48.1072 76.9135 32.4641C76.9135 16.8211 64.2022 4.13989 48.5219 4.13989C32.8417 4.13989 20.1303 16.8211 20.1303 32.4641C20.1303 48.1072 32.8417 60.7884 48.5219 60.7884Z" fill="#FDDA44" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M44.998 55.1134C34.8798 53.9583 27.2785 47.5804 27.6308 44.4165C27.9832 41.3028 36.4403 39.6456 46.5586 40.8006C56.6769 41.9557 64.5299 45.3707 64.1775 48.5345C63.8755 51.6984 55.1163 56.2684 44.998 55.1134Z" fill="#FDE272" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M66.6079 16.7399C67.131 15.5453 66.006 13.9018 64.0952 13.0691C62.1844 12.2365 60.2113 12.5298 59.6882 13.7244C59.1652 14.919 60.2902 16.5625 62.201 17.3952C64.1118 18.2279 66.0848 17.9345 66.6079 16.7399Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M40.81 30.3238C43.019 30.0653 44.6391 28.4042 44.4286 26.6136C44.2181 24.8231 42.2566 23.5811 40.0476 23.8396C37.8386 24.0981 36.2185 25.7592 36.429 27.5498C36.6395 29.3403 38.601 30.5823 40.81 30.3238Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M52.4486 13.5312C54.4622 15.0881 54.3112 17.5991 52.499 19.2564C50.9385 20.7127 48.2705 20.9136 45.6025 19.1559L52.4486 13.5312Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M51.5929 14.3849C53.0527 15.5902 53.0024 17.3479 51.4922 18.5532C49.8813 19.8087 47.7671 19.1057 46.2065 18.1515C46.3072 18.7541 46.3575 19.407 46.4582 20.0096C48.7235 18.1515 51.0391 16.2431 53.3044 14.3849C54.5126 13.3805 52.801 11.7232 51.5929 12.6774C49.3276 14.5356 47.012 16.444 44.7467 18.3021C44.1426 18.8043 44.3943 19.8088 44.9984 20.1603C47.616 21.7674 51.1398 22.2696 53.5058 19.9092C55.6201 17.7999 55.62 14.5858 53.3044 12.6272C52.0963 11.7233 50.3847 13.3805 51.5929 14.3849Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M66.067 56.1552C71.5537 52.5155 74.5665 47.4118 72.7962 44.7558C71.0259 42.0998 65.1429 42.8973 59.6562 46.5369C54.1695 50.1766 51.1567 55.2803 52.927 57.9363C54.6973 60.5923 60.5803 59.7949 66.067 56.1552Z" fill="#F4504D" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M72.7861 43.2114C73.3901 37.9382 68.7589 32.5145 62.9698 35.829C57.4325 38.9929 54.8148 48.3338 52.8012 54.31C52.8012 54.31 54.9658 60.5373 61.51 57.725C68.0541 54.9629 72.2323 48.4845 72.7861 43.2114Z" fill="#EDC51B" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M72.786 43.2114C73.3901 37.9382 71.2758 35.5779 66.5942 36.7832C61.8623 37.9884 54.3617 48.2334 52.8012 54.31C52.8012 54.31 54.9658 60.5373 61.51 57.725C68.0541 54.9629 72.2323 48.4845 72.786 43.2114Z" fill="#E53E3E" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M53.204 58.2775L72.4842 44.3665L73.9944 45.8228L56.0231 61.0898L53.204 58.2775Z" fill="#F4504D" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M74.951 47.5303C73.8435 44.0651 70.8734 44.3162 65.0843 46.9778C59.2952 49.6395 54.664 54.7117 54.664 58.3778C54.664 62.0439 61.5605 64.9065 67.3496 62.295C73.5414 59.3822 76.2095 51.5479 74.951 47.5303Z" fill="#F4504D" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M70.9052 61.192L72.3149 59.7576L68.2168 55.7495L66.8071 57.1839L70.9052 61.192Z" fill="#FDDA44" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M67.9702 56.8741C68.3595 56.478 68.498 55.9837 68.2796 55.7701C68.0612 55.5565 67.5687 55.7045 67.1794 56.1006C66.7901 56.4968 66.6516 56.991 66.87 57.2046C67.0883 57.4182 67.5809 57.2703 67.9702 56.8741Z" fill="#FDDA44" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M70.9738 61.1399C70.269 60.4368 74.2962 55.2139 75.303 54.1593C76.3098 53.1047 77.7194 52.8536 78.4745 53.5065C79.1792 54.2095 78.9275 55.6157 77.9207 56.6703C76.8635 57.7752 71.6786 61.843 70.9738 61.1399Z" fill="#38B4F2" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M72.3333 59.7338C71.6285 59.0308 66.4435 63.1488 65.4368 64.2034C64.43 65.258 64.1783 66.6642 64.883 67.3673C65.5878 68.0704 66.9973 67.769 68.0544 66.7144C69.0612 65.71 73.0883 60.4369 72.3333 59.7338Z" fill="#38B4F2" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M36.1886 58.8798C37.6484 50.041 36.5913 39.1935 32.0104 32.7151C26.7751 25.3327 22.7479 29.7521 17.5126 35.7785" stroke="#95A2A3" stroke-width="7" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
    <path d="M26.6746 27.2411C26.1209 28.3962 24.661 28.6473 23.6039 29.3002C22.3957 30.0535 21.54 31.1081 21.54 32.5645C21.54 36.7328 26.3726 38.7416 29.9467 38.4403C31.7086 38.2896 31.7086 35.5777 29.9467 35.7284C28.2855 35.879 26.4229 35.5777 25.1645 34.3724C24.4597 33.7196 23.906 32.665 24.6611 31.9116C25.2148 31.309 26.2719 31.0579 27.027 30.6561C27.8828 30.1539 28.5875 29.5513 29.0406 28.6473C29.7957 27.0403 27.4297 25.6341 26.6746 27.2411Z" fill="#442A25" data-sentry-element="path" data-sentry-source-file="chibi-rocket.tsx" />
  </svg>;